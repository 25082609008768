import React from "react"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { map, find, pick, keys } from "lodash";
import HcBarChart from "Reports/HealthCare/HcBarChart";

const DQualityAudit = () => {

    const tableColumns = ["Oct-23", "Sep-23", "Aug-23", "Jul-23", "Jun-23", "May-23", "Apr-23"]
    const tabledata = [
        { label: "processed", valueOne: 98616, valueTwo: 87555, valueThree: 105292, valueFour: 89592, valueFive: 85842, valueSix: 95027, valueSeven: 89941 },
        { label: "audited", valueOne: 5319, valueTwo: 5269, valueThree: 5933, valueFour: 5836, valueFive: 5836, valueSix: 5348, valueSeven: 5790 },
        { label: "linesAudited", valueOne: 240684, valueTwo: 150969, valueThree: 265723, valueFour: 259201, valueFive: 262969, valueSix: 233332, valueSeven: 254206 },
    ]
    var model = {
        valueOne: null,
        valueTwo: null,
        valueThree: null,
        valueFour: null,
        valueFive: null,
        valueSix: null,
        valueSeven: null
    };
    var label = map(tabledata, 'label')
    var data1 = find(tabledata, function (o) { return o.label === "processed"; });
    var data2 = find(tabledata, function (o) { return o.label === "audited"; });
    var data3 = find(tabledata, function (o) { return o.label === "linesAudited"; });
    var result1 = pick(data1, keys(model));
    var result2 = pick(data2, keys(model));
    var result3 = pick(data3, keys(model));
    var processedData = Object.values(result1)
    var auditedData = Object.values(result2)
    var linesAuditedData = Object.values(result3)

    const seriesProcessed = [
        {
            name: label[0],
            data: processedData,
        },
        {
            name: label[2],
            data: linesAuditedData,
        },

    ]
    const seriesAudited = [
        {
            name: label[1],
            data: auditedData,
        },
    ]
    const stateProcessed = {
        horizontal: true,
        height: "350",
        title: "",
        minValue: 100
    }
    const stateAudited = {
        horizontal: false,
        height: "350",
        title: "",
        minValue: 100
    }
    const reportsAudited = [5.39, 6.02, 5.63, 6.51, 6.8, 5.63, 6.44]
    const qualityAccuracy = [99.29, 99.36, 99.39, 99.29, 99.35, 99.24, 99.36]
    const columns = ["Oct-23", "Sep-23", "Aug-23", "Jul-23", "Jun-23", "May-23", "Apr-23"]
    const reportsAuditedSeries = [
        {
            name: "Audited %",
            data: reportsAudited
        }
    ]
    const reportsAuditedState = {
        horizontal: false,
        height: "350",
        minValue: 0,
        symbol: "%"
    }
    const qualityAccuracySeries = [
        {
            name: "Accuracy %",
            data: qualityAccuracy
        }
    ]
    const qualityAccuracyState = {
        horizontal: false,
        height: "350",
        minValue: 99,
        symbol: "%"
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg={6}>
                    <Card >
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">No. of Reports Processed & No. of Lines Audited
                        </CardHeader>
                        <CardBody>
                            <HcBarChart dataColors='["--bs-danger","--bs-primary", "--bs-success"]' tableColumns={tableColumns} dataseries={seriesProcessed} state={stateProcessed} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card >
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">No. of Reports Audited
                        </CardHeader>
                        <CardBody>
                            <HcBarChart dataColors='["--bs-danger","--bs-primary", "--bs-success"]' tableColumns={tableColumns} dataseries={seriesAudited} state={stateAudited} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card >
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">% of Sample Reports Audited
                        </CardHeader>
                        <CardBody>
                            <HcBarChart dataColors='["--bs-success"]' tableColumns={columns} dataseries={reportsAuditedSeries} state={reportsAuditedState} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card >
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Internal Quality Accuracy % 
                        </CardHeader>
                        <CardBody>
                            <HcBarChart dataColors='["--bs-primary"]' tableColumns={columns} dataseries={qualityAccuracySeries} state={qualityAccuracyState} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>

    )
}

export default DQualityAudit
