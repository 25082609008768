import DataTable from "Components/DataTable";
import React from "react"
import { Card, CardBody, CardHeader, Table } from "reactstrap";

const HcClientEscalations = () => {
    const tableColumns = [
        { label: "Date of Escalation" }, { label: "Internal/External " }, { label: "Client Name" }, { label: "Dept. of Escalation " },
        { label: "Issue Description" }, { label: "Volume Effected" }, { label: "Assigned To (for resolution)" },
        { label: "Status" }, { label: "Resolution Rating (1-10)" }
    ]

    const tableData = [
        { col: ["09/26/2023", "External",  "North Platte Surgical Center (NPLS)", "Healthcare Documentation",
        "The transcribed report did not match the dictation. Different report uploaded against  the dictation.",
        "NA","Director-Ops & Quality Assurance ","Resolved on 09/26/2023",10] },
        { col: ["09/29/2023", "External", "Orthopaedic Medical Group of Tampa Bay (OMTB)", "Healthcare Documentation",
        "Addendum not added to the main file",
        "NA","Director-Ops & Quality Assurance ", "Resolved on 09/29/2023",10] },
        { col: ["09/01/2023", "External", "Vail Valley Surgery Center (VVSC)", "Healthcare Documentation",
        "Templates created but failed to attach.",
        "NA","Director-Ops & Quality Assurance ", "Resolved on 09/02/2023",10] },
        { col: ["09/07/2023", "External", "North Bay Regional Surgery Center (NBSC)", "Healthcare Documentation",
        "Failed to raise NTM exception.",
        "NA","Director-Ops & Quality Assurance ", "Resolved on 09/08/2023",10] },
        { col: ["09/15/2023", "External", "Metro Specialty Surgery Center (MSSC)", "Healthcare Documentation",
        "Failed to release PSM exception.",
        "NA","Director-Ops & Quality Assurance ", "Resolved on 09/15/2023",10] },
        { col: ["09/22/2023", "External", "The Center for Advanced Spine and Joint Surgery (CASJ)", "Healthcare Documentation",
        "Uploaded the two similar reports.",
        "NA","Director-Ops & Quality Assurance ", "Resolved on 09/23/2023",10] },
        { col: ["09/26/2023", "External", "Surgery Center of Reno (RENO)", "Healthcare Documentation",
        "Physician dictated wrong MRN, failed to raise exception.",
        "NA","Director-Ops & Quality Assurance ", "Resolved on 09/27/2023",10] },
        { col: ["09/27/2023", "External", "Campus Surgery Center (CSC1)", "Healthcare Documentation",
        "Failed to upload multiple reports.",
        "NA","Director-Ops & Quality Assurance ", "Resolved on 09/28/2023",10] },
        { col: ["09/30/2023", "External",  "Mile High SurgiCenter (MIHI)", "Healthcare Documentation",
        "Insertion of wrong template.",
        "NA","Director-Ops & Quality Assurance ", "Resolved on 09/30/2023",10] },
        { col: ["09/13/2023", "External","Orthosouth Surgery Center – Southaven (OSSC)", "Healthcare Documentation",
        "Error in transcribed text.",
        "NA","Director-Ops & Quality Assurance ", "Resolved on 09/13/2023",10] },
        { col: ["09/15/2023", "External","Fairfax Surgical Center (FAIR)", "Healthcare Documentation",
        "Wrong anatomic side.",
        "NA","Director-Ops & Quality Assurance ", "Resolved on 09/15/2023",10] },
        { col: ["09/23/2023", "External", "Dearborn Surgery Center (DBSC)", "Healthcare Documentation",
        "Incorrect demographics.",
        "NA","Director-Ops & Quality Assurance ", "Resolved on 09/23/2023",10] },
          // {
        //     date: "09/26/2023", name: "External", clientName: "North Platte Surgical Center (NPLS)", escalation: "Healthcare Documentation",
        //     issue: "The transcribed report did not match the dictation. Different report uploaded against  the dictation.",
        //     volume: "NA", resolution: "Director-Ops & Quality Assurance ", status: "Resolved on 09/26/2023 ", rating: 10
        // },
        // {
        //     date: "09/29/2023", name: "External", clientName: "Orthopaedic Medical Group of Tampa Bay (OMTB)", escalation: "Healthcare Documentation",
        //     issue: "Addendum not added to the main file",
        //     volume: "NA", resolution: "Director - Ops & Quality Assurance ", status: "Resolved on 09/29/2023 ", rating: 10
        // },
        // {
        //     date: "09/01/2023", name: "External", clientName: "Vail Valley Surgery Center (VVSC)", escalation: "Healthcare Documentation",
        //     issue: "Templates created but failed to attach.",
        //     volume: "NA", resolution: "Director - Ops & Quality Assurance ", status: "Resolved on 09/02/2023 ", rating: 10
        // },
        // {
        //     date: "09/07/2023", name: "External", clientName: "North Bay Regional Surgery Center (NBSC)", escalation: "Healthcare Documentation",
        //     issue: "Failed to raise NTM exception.",
        //     volume: "NA", resolution: "Director - Ops & Quality Assurance ", status: "Resolved on 09/08/2023  ", rating: 10
        // },
        // {
        //     date: "09/15/2023", name: "External", clientName: "Metro Specialty Surgery Center (MSSC)", escalation: "Healthcare Documentation",
        //     issue: "Failed to release PSM exception.",
        //     volume: "NA", resolution: "Director - Ops & Quality Assurance ", status: "Resolved on 09/15/2023  ", rating: 10
        // },

        // {
        //     date: "09/22/2023", name: "External", clientName: "The Center for Advanced Spine and Joint Surgery (CASJ)", escalation: "Healthcare Documentation",
        //     issue: "Uploaded the two similar reports.",
        //     volume: "NA", resolution: "Director - Ops & Quality Assurance ", status: "Resolved on 09/23/2023  ", rating: 10
        // },
        // {
        //     date: "09/26/2023", name: "External", clientName: "Surgery Center of Reno (RENO)", escalation: "Healthcare Documentation",
        //     issue: "Physician dictated wrong MRN, failed to raise exception.",
        //     volume: "NA", resolution: "Director - Ops & Quality Assurance ", status: "Resolved on 09/27/2023  ", rating: 10
        // },
        // {
        //     date: "09/27/2023", name: "External", clientName: "Campus Surgery Center (CSC1)", escalation: "Healthcare Documentation",
        //     issue: "Failed to upload multiple reports.",
        //     volume: "NA", resolution: "Director - Ops & Quality Assurance ", status: "Resolved on 09/28/2023  ", rating: 10
        // },
        // {
        //     date: "09/30/2023 ", name: "External", clientName: "Mile High SurgiCenter (MIHI)", escalation: "Healthcare Documentation",
        //     issue: "Insertion of wrong template.",
        //     volume: "NA", resolution: "Director - Ops & Quality Assurance ", status: "Resolved on 09/30/2023  ", rating: 10
        // },
        // {
        //     date: "09/13/2023", name: "External", clientName: "Orthosouth Surgery Center – Southaven (OSSC)", escalation: "Healthcare Documentation",
        //     issue: "Error in transcribed text.",
        //     volume: "NA", resolution: "Director - Ops & Quality Assurance ", status: "Resolved on 09/13/2023  ", rating: 10
        // },
        // {
        //     date: "09/15/2023", name: "External", clientName: "Fairfax Surgical Center (FAIR)", escalation: "Healthcare Documentation",
        //     issue: "Wrong anatomic side.",
        //     volume: "NA", resolution: "Director - Ops & Quality Assurance ", status: "Resolved on 09/15/2023  ", rating: 10
        // },
        // {
        //     date: "09/23/2023", name: "External", clientName: "Dearborn Surgery Center (DBSC)", escalation: "Healthcare Documentation",
        //     issue: "Incorrect demographics.",
        //     volume: "NA", resolution: "Director - Ops & Quality Assurance ", status: "Resolved on 09/23/2023  ", rating: 10
        // },
    ]
    return (
        <React.Fragment>
            <Card >
                <CardHeader className="bg-transparent border-bottom text-uppercase h6">Client Escalations</CardHeader>
                <CardBody>
                    {/* <div className="table-responsive">
                        <Table className="table table-sm mb-1">
                            <thead>
                                <tr>
                                    {
                                        tableColumns.map((r) => {
                                            return <th>{r.label}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableData.map((r) => {
                                        return <tr>
                                            <td>{r.date}</td>
                                            <td>{r.name}</td>
                                            <td>{r.clientName}</td>
                                            <td>{r.escalation}</td>
                                            <td>{r.issue}</td>
                                            <td>{r.volume}</td>
                                            <td>{r.resolution}</td>
                                            <td>{r.status}</td>
                                            <td>{r.rating}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </div> */}
                       <DataTable tableColumns={tableColumns} tableData={tableData} />
                </CardBody>
            </Card>
        </React.Fragment>

    )
}

export default HcClientEscalations
