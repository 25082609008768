import React, { Fragment, useState } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import Chart from 'react-google-charts';
import './OrganizationChartDemo.css';

export default function OrganizationalChart() {
    const data = [
        [
            {
                v: "nimble Executive Team Murali Krishna R Managing Director",
                f: '<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>',
            },
            "",
            "The President",
        ],
        ["<div>Vice President-HR-1</div><div>Anuradha D</div>", "<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ["<div>Sr. Manager-HR-1</div><div>Venkat Y</div>", "<div>Vice President-HR-1</div><div>Anuradha D</div>", ""],
        ["<div>Manager-HR-1</div><div>Anitha K</div>", "<div>Vice President-HR-1</div><div>Anuradha D</div>", ""],
        ["Communications & Others – 6", "<div>Vice President-HR-1</div><div>Anuradha D</div>", ""],
        ["<div>Sr. HR Business Partner - 1</div><div>HR Business Partner - 1</div>", "<div>Sr. Manager-HR-1</div><div>Venkat Y</div>", ""],
        ["HR Executives - 7", "<div>Sr. Manager-HR-1</div><div>Venkat Y</div>", ""],
        ["Sr. HR Executive - 1", "<div>Manager-HR-1</div><div>Anitha K</div>", ""],
    ];

    const options = {
        allowHtml: true,
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Organizational Chart - HR & Others</CardHeader>
                        <CardBody>
                            <Chart
                                chartType="OrgChart"
                                data={data}
                                options={options}
                                width="100%"
                                height="400px"
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    )
}
