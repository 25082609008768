import React, { useState } from "react"
import { Table, Card, CardBody, CardHeader, Row, Col } from "reactstrap"

const TechnologyTools = () => {
    const tableColumns = ["System Security", "Workflow Tools", "Transcription Tools", "Communication Tools", "Coding Tools", "Finance & Accounts"]
    const tableData = [
        { col: ["Secured Connectivity via SSN VPN", "TranSmart", "Stedman’s Medical Dictionary", "Skype/Teams–External Com.", "3M Software", "Tally"] },
        { col: ["Firewall/Malware/Spam Protection", "AutoScribe", "Quick Look Electronic Drug Reference", "Thunderbird Mail System", "", ""] },
        { col: ["ActZero", "RPA Bots", "American Heritage Dictionary of English Language", "Zoom/GTM/Teams-Team Meet TX Text Control Integrated English Spell Check/Med Dictionary Teams/ Spar", "", ""] },
        { col: ["", "TX Text Control", "Integrated English Spell Check/Med Dictionary", "Teams/ Spark-External communication", "", ""] },
        { col: ["", "Express Scribe Audio Player", "Text Highlighte", "", "", ""] },
        { col: ["", "Open Office/MS Word", "InfoRapid/Examine32 Sample Search Engine ", "", "", ""] }
    ]

    return (
        <React.Fragment>
            <Card >
                <CardHeader className="bg-transparent border-bottom text-uppercase h4">Technology / Workflow Tools</CardHeader>
                <CardBody>
                    <Table className="table table-sm mb-1">
                        <thead>
                            <tr>
                                {
                                    tableColumns.map((r) => {
                                        return <th>{r}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData.map((r) => {
                                    return <tr>
                                        {r.col.map((c) => {
                                            return <td>{c}</td>
                                        })}
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </React.Fragment>

    )
}
export default TechnologyTools;