import React from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../getChartColorsArray";
const HcLineChart = ({ dataColors, data,state }: any) => {
    const lineEChartColors = getChartColorsArray(dataColors);
    const options = {
        tooltip: {
            trigger: "axis",
        },
        grid: {
            zlevel: 0,
            x: 50,
            x2: 50,
            y: 30,
            y2: 30,
            borderWidth: 0,
        },
        xAxis: {
            type: "category",
            data: state.labels,
            axisLine: {
                lineStyle: {
                    color: "#8791af",
                },
            },
        },
        yAxis: {
            min: state.minValue,
            type: "value",
            axisLine: {
                lineStyle: {
                    color: "#8791af"
                },
            },
            splitLine: {
                lineStyle: {
                    color: "rgba(166, 176, 207, 0.1)"
                }
            }
        },
        series: [
            {
                data: data,
                type: "line",
            },
        ],
        color: lineEChartColors,
        textStyle: {
            color: ["#8791af"],
        },
    };
    return (
        <React.Fragment>
            <ReactEcharts style={{ height: 200 }} option={options} />
        </React.Fragment>
    );
};
export default HcLineChart;
