// import React from "react"
// import ReactApexChart from "react-apexcharts"
// import getChartColorsArray from "../../../getChartColorsArray";

// const MultipleBarChart = ({ dataColors, tableColumns, dataseries, state }: any) => {

//     const spineareaChartColors = getChartColorsArray(dataColors);
//     const series = dataseries
//     const options = {
//         chart: {
//             toolbar: {
//                 show: false,
//                 innerWidth: "45%",
//             },
//         },
//         plotOptions: {
//             bar: {
//                 horizontal: state.horizontal,
//                 endingShape: "rounded",
//                 dataLabels: {
//                     position: "top", // top, center, bottom
//                 },
//             },
//         },
//         dataLabels: {
//             enabled: true,
//             formatter: function (val: any) {
//                 return state.symbol ? val + state.symbol : val
//             },
//         },
//         stroke: {
//             show: true,
//             width: 1,
//             colors: ["transparent"],
//         },

//         colors: spineareaChartColors,
//         xaxis: {
//             categories: tableColumns
//         },
//         yaxis: {
//             min: state.minValue,
//             title: {
//                 text: state.title,
//             },

//         }, title: {
//             // text: "Points",
//         },
//         grid: {
//             borderColor: "#f1f1f1",
//         },
//         fill: {
//             opacity: 1,
//         },
//         tooltip: {
//             y: {
//                 formatter: function (val: any) {
//                     return val;
//                 },
//             },
//         },
//     }
//     return (
//         <React.Fragment>
//             <ReactApexChart options={options} series={series} type="bar" height={state.height} />
//         </React.Fragment >

//     )
// }
// export default MultipleBarChart;

import React from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "../../../../Components/Common/ChartDynamicColor";
import { map } from "lodash";

const Line = ({ dataColors, tableData, state }: any) => {
    const lineEChartColors = getChartColorsArray(dataColors);
    var name = map(tableData, 'name')
    var value = map(tableData, 'value')
    const options = {
        tooltip: {
            trigger: "axis",
        },
        grid: {
            zlevel: 0,
            x: 30,
            x2: 30,
            y: 30,
            y2: 30,
            borderWidth: 0,
        },
        xAxis: {
            type: "category",
            data: name,
            axisLine: {
                lineStyle: {
                    color: "#8791af",
                },
            },
        },
        yAxis: {
            type: "value",
            axisLine: {
                lineStyle: {
                    color: "#8791af"
                },

            },
            min: state.minValue,
            splitLine: {
                lineStyle: {
                    color: "rgba(166, 176, 207, 0.1)"
                }
            }
        },
        series: [
            {
                data: value,
                type: "line",
            },
        ],
        color: lineEChartColors,
        textStyle: {
            color: ["#8791af"],
        },
    };
    return (
        <React.Fragment>
            <ReactEcharts style={{ height: "250px" }} option={options} />
        </React.Fragment>
    );
};
export default Line;
