import React, { Fragment, useState } from 'react';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import Chart from 'react-google-charts';
import './OrganizationChartDemo.css';

export default function HealthCareDocumentation() {
    const data = [
        [
            {
                v: "nimble Executive Team Murali Krishna R Managing Director",
                f: '<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>',
            },
            "",
            "The President",
        ],
        ["<div>Sr Vice President</div><div>Mayur Damani</div>", "<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ["<div>Director ACT & Strategic Initiatives</div><div>Pradyut Jan</div>", "<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ["<div>Director Ops.–HYD</div><div>Manas Mishra</div>", "<div>Sr Vice President</div><div>Mayur Damani</div>", ""],
        ["<div>Director Ops.–SEC</div><div>Deepak Adapa</div>", "<div>Sr Vice President</div><div>Mayur Damani</div>", ""],
        ["<div>Director Ops.VJA</div><div>Nagaraju.D</div>", "<div>Sr Vice President</div><div>Mayur Damani</div>", ""],
        ["<div>OperationsTeam 33</div>", "<div>Sr Vice President</div><div>Mayur Damani</div>", ""],
        ["<div>Audit, Certification &Training Team - 8</div>", "<div>Director ACT & Strategic Initiatives</div><div>Pradyut Jan</div>", ""],
        ["<div>Managers–Transition – 2,TL/AMs–Operations-4</div>", "<div>Director Ops.–HYD</div><div>Manas Mishra</div>", ""],
        ["<div>Manager -1,TL/AMs-Transition-3</div>", "<div>Director Ops.–SEC</div><div>Deepak Adapa</div>", ""],
        ["<div>AMs/ Team Leads - 4</div>", "<div>Director Ops.VJA</div><div>Nagaraju.D</div>", ""],
        ["<div>Healthcare Documentation Associates,</div><div>Proof Readers,</div><div>Quality Controllers,</div><div>Account Experts - 226</div>", "<div>Manager -1,TL/AMs-Transition-3</div>", ""],
    ];

    const options = {
        allowHtml: true,
    };
    return (
        <Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Card className='overflow'>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Organizational Chart - Healthcare Documentation</CardHeader>
                        <CardBody>
                            <Chart
                                chartType="OrgChart"
                                data={data}
                                options={options}
                                width="100%"
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Fragment>

    )
}
