import DataTable from "Components/DataTable"
import React from "react"
import { Table, Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import HcMapChart from "./HcMapChart"

const HcGeographicLocations = () => {
    const tableColumns = [{ label: "State" }, { label: "Total" }]
    const tableData = [
        { col: ["Florida", 65] },
        { col: ["California", 53] },
        { col: ["Texas ", 40] },
        { col: ["Tennessee", 17] },
        { col: ["Missouri ", 12] },
        { col: ["Pennsylvania", 12] },
        { col: ["New jersey", 10] },
        { col: ["Newyork", 10] },
        { col: ["Arizona ", 9] },
        { col: ["Michigan", 9] },
        { col: ["Kansas", 8] },
        { col: ["Nevada", 8] },
        { col: ["Georgia ", 7] },
        { col: ["Utah", 7] },
        { col: ["Virginia ", 6] },
        { col: ["Oregon ", 5] },
        { col: ["lllinois ", 4] },
        { col: ["Louisiano", 4] },
        { col: ["MaryLand ", 4] },
        { col: ["North Carolina", 4] },
        { col: ["Alaska ", 3] },
        { col: ["Colorado", 3] },
        { col: ["New Hempshire ", 3] },
        { col: ["Ohio", 3] },
        { col: ["Alabama", 2] },
        { col: ["Indiana", 2] },
        { col: [" Mississipi ", 2] },
        { col: ["Montana", 2] },
        { col: ["South carolina", 2] },
        { col: ["Arkansas", 1] },
        { col: ["Connecticut ", 1] },
        { col: ["Hawaii", 1] },
        { col: ["Idaho ", 1] },
        { col: ["Kentucky ", 1] },
        { col: ["Lowa ", 1] },
        { col: ["Massachusetts", 1] },
        { col: ["Minnesota", 1] },
        { col: ["Nebraska ", 1] },
        { col: ["New Mexico ", 1] },
        { col: ["Oklahoma", 1] },
        { col: ["Virgin islands ", 1] }

    ]

    return (
        <React.Fragment>
            <Row>
                {/* <Col lg={6}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Geographic Locations –328(41 States)</CardHeader>
                        <CardBody>
                            <HcMapChart />
                        </CardBody>
                    </Card>
                </Col> */}
                <Col lg={6}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Geographic Locations –328(41 States)</CardHeader>
                        <CardBody>
                            <DataTable tableColumns={tableColumns} tableData={tableData} />
                        </CardBody>

                    </Card>
                </Col>
            </Row>

        </React.Fragment >

    )
}
export default HcGeographicLocations;