import React from "react"
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap"
import HcBarChart from "../HcBarChart"
import { map, minBy } from "lodash";
import HcLineChart from "Reports/HealthCare/HcLineChart";

const DVolumeSnapshort = () => {
    const tableColumns = ["Jan 2023", "Feb 2023", "Mar 2023", "Apr 2023", "May 2023", "June 2023", "July 2023", "Aug 2023", "Sep 2023", "Oct 2023"]
    const lineCount = [
        { label: "Sep 2023", valueOne: 3766779 },
        { label: "Aug 2023 ", valueOne: 4069139 },
        { label: "Jul 2023", valueOne: 3430385 },
        { label: "June 2023", valueOne: 3916455 },
        { label: "May 2023 ", valueOne: 3911184 },
        { label: "Apr 2023", valueOne: 3800661 },
        { label: "Mar 2023", valueOne: 4236722 },
        { label: "Feb 2023", valueOne: 3672726 },
        { label: "Jan 2023", valueOne: 3650611 },


    ]
    var data1 = map(lineCount, 'valueOne')
    var labels = map(lineCount, 'label')
    const lineCountState = {
        horizontal: true,
        height: "300",
        minValue: 3000000,
    }
    const series = [
        {
            name: 'Line Count',
            data: data1
        },
    ]
    const costDollar = [
        { label: "Oct 2023", value: 0.033241396181603 },
        { label: "Sep 2023", value: 0.033241396181603 },
        { label: "Aug 2023 ", value: 0.0317018162690484 },
        { label: "Jul 2023", value: 0.0357188459647049 },
        { label: "June 2023", value: 0.0313528150192818 },
        { label: "May 2023 ", value: 0.0318156830963264 },
        { label: "Apr 2023", value: 0.0328030932501273 },
        { label: "Mar 2023", value: 0.029 },
        { label: "Feb 2023", value: 0.032840636063466 },
        { label: "Jan 2023", value: 0.0314819452441676 },

    ]
    var costData = map(costDollar, 'value');
    var costLabels = map(costDollar, 'label');
    var minValue = minBy(map(costDollar, 'value'))
    const costDollarState = {
        labels: costLabels,
        minValue: minValue
    }

    const accurancyOne = [20.48, 20.3, 21.02, 21.25, 23.46, 20.46, 21.9, 21, 21.42, 21.38]
    const accurancyTwo = [2.32, 2.81, 3.35, 3.11, 3.9, 2.51, 3.2, 2.23, 2.53, 3.07]
    const audited = [6.59, 7, 6.44, 6.46, 5.43, 6.7, 6.52, 5.77, 5.75, 5.37]
    const ASAE = [43.64, 42.6, 42.24, 41.53, 42.91, 42.97, 42.02, 42.68, 42.63, 43.01]
    const AS = [7.37, 7.35, 7.04, 6.56, 6.28, 6.61, 6.17, 6.12, 5.89, 5.85]
    const ASAESeries = [
        {
            name: "Accuracy Less than 99%",
            data: accurancyOne

        },
        {
            name: "Accuracy Less than 95%",
            data: accurancyTwo

        }
    ]
    const ASAEState = {
        horizontal: true,
        height: "600",
        minValue: 0,
        symbol: "%"
    }
    const auditedSeries = [
        {
            name: "ASAE%",
            data: ASAE

        },
        {
            name: "Audited",
            data: audited

        },
        {
            name: "AS%",
            data: AS

        },

    ]
    const auditedState = {
        horizontal: true,
        height: "600",
        minValue: 0,
        symbol: "%"
    }
    const average = [
        { label: "Oct 23", value: 40.81 },
        { label: "Sep 23", value: 40.81 },
        { label: "Aug 23 ", value: 40.58 },
        { label: "Jul 23", value: 40.63 },
        { label: "June 23", value: 40.74 },
        { label: "May 23 ", value: 40.85 },
        { label: "Apr 23", value: 40.85 },
        { label: "Mar 23", value: 40.9 },
        { label: "Feb 23", value: 40.9 },
        { label: "Jan 23", value: 41 },
    ]
    var averagetData = map(average, 'value');
    var averageLabels = map(average, 'label');
    const averageSeries = [
        {
            name: "Average",
            data: averagetData
        }
    ]
    const averageState = {
        horizontal: true,
        height: "280",
        minValue: 40,
        symbol: "%"
    }
    const days = [
        { label: "Oct 23", value: 21 },
        { label: "Sep 23", value: 21 },
        { label: "Aug 23 ", value: 23 },
        { label: "Jul 23", value: 21 },
        { label: "June 23", value: 22 },
        { label: "May 23 ", value: 23 },
        { label: "Apr 23", value: 21 },
        { label: "Mar 23", value: 23 },
        { label: "Feb 23", value: 20 },
        { label: "Jan 23", value: 21 },
    ]
    var daysData = map(days, 'value');
    var daysLabels = map(days, 'label');
    const daysSeries = [
        {
            name: "Days",
            data: daysData
        }
    ]
    const daysState = {
        horizontal: true,
        height: "280",
        title: "",
        minValue: 19
    }

    const accuracy = [
        { label: "Oct 2023", value: 99.29 },
        { label: "Sep 2023", value: 99.36 },
        { label: "Aug 2023 ", value: 99.39 },
        { label: "Jul 2023", value: 99.29 },
        { label: "June 2023", value: 99.35 },
        { label: "May 2023 ", value: 99.24 },
        { label: "Apr 2023", value: 99.36 },
        { label: "Mar 2023", value: 99.16 },
        { label: "Feb 2023", value: 99.34 },
        { label: "Jan 2023", value: 99.34 },

    ]
    var accuracyData = map(accuracy, 'value');
    var accuracyLabels = map(accuracy, 'label');
    const accuracyState = {
        labels: accuracyLabels,
        minValue: 99
    }
    // const cost = [
    //     { label: "Mar ", value: 0.033 },
    //     { label: "Sep ", value: 0.033 },
    //     { label: "Oct ", value: 0.032 }

    // ]
    // var costData = map(cost, 'value');
    // var costLabels = map(cost, 'label');
    // const costState = {
    //     labels: costLabels,
    //     minValue: 0.031
    // }

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Number of lines received(ready for transciption  ) </CardHeader>
                        <CardBody>
                            <HcBarChart dataColors='["--bs-primary","--bs-success"]' tableColumns={labels} dataseries={series} state={lineCountState} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">AVG COST PER LINE</CardHeader>
                        <CardBody>
                            <HcLineChart dataColors='["--bs-primary"]' data={costData} state={costDollarState} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Average Lines per case</CardHeader>
                        <CardBody>
                            <HcBarChart dataColors='["--bs-primary", "--bs-success"]' tableColumns={averageLabels} dataseries={averageSeries} state={averageState} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Internal Accuracy</CardHeader>
                        <CardBody>
                            <HcLineChart dataColors='["--bs-danger","--bs-primary", "--bs-success"]' data={accuracyData} state={accuracyState} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Businnes Days</CardHeader>
                        <CardBody>
                            <HcBarChart dataColors='["--bs-primary", "--bs-success"]' tableColumns={daysLabels} dataseries={daysSeries} state={daysState} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Performance Ratings : Internal accuracy</CardHeader>
                        <CardBody>
                            <HcBarChart dataColors='["--bs-primary","--bs-success","--bs-danger"]' tableColumns={tableColumns} dataseries={ASAESeries} state={ASAEState} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Performance Ratings: ASAE & % Of files audited & AS%</CardHeader>
                        <CardBody>
                            <HcBarChart dataColors='["--bs-primary","--bs-success","--bs-danger"]' tableColumns={tableColumns} dataseries={auditedSeries} state={auditedState} />
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </React.Fragment >

    )
}
export default DVolumeSnapshort;