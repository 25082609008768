import DataTable from "Components/DataTable";
import React from "react"
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";

const HcTerminationTracker = () => {
    const tableColumns = [
        { label: "Client Name" }, { label: "Affiliation" }, { label: "MonthlyRevenue (at time of term)" }, { label: "Annualized Revenue (at time of term)" },
        { label: "VolumeMonthly" }, { label: "VolumeYearly" }, { label: "Pricing per chart / line" },
        { label: "State" }, { label: "TermCode" }, { label: "StartDate" }, { label: "Date TermNotice Rcvd" }, { label: "TermDate" }, { label: "Run-downlength" },
        { label: "End of Run-down" }, { label: "LegalEngaged?" }, { label: "Lead/Referr al Source" }, { label: "Salesperson" }, { label: "Who took business?" }, { label: "Notes/Comments" }
    ]
    const tableTwoColumns = [
        { label: "  Termination Codes/Reasons" }, { label: "" }, { label: "" }
    ]
    const tableTwoData = [
        { col: ["1 - Management company took over operations", "2 -Client brought coding in-house", "3 -Switched to another company"] }
    ]

    return (
        <React.Fragment>
            <Card >
                <CardHeader className="bg-transparent border-bottom text-uppercase h6">Termination Tracker – No Terminations</CardHeader>
                <CardBody>
                    <div className="table-responsive">
                        <Table className="table table-sm mb-1">
                            <thead>
                                <tr>
                                    {
                                        tableColumns.map((r) => {
                                            return <th>{r.label}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                }
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
                <div>
                    <h6 >Note: </h6>
                    1 - Management company took over operations |

                    2 -Client brought coding in-house |

                    3 -Switched to another company
                </div>
            </Card>
        </React.Fragment>

    )
}

export default HcTerminationTracker
