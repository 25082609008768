import React from 'react'
import ReactApexChart from 'react-apexcharts';
import { map } from "lodash";
import getChartColorsArray from "../getChartColorsArray";


const HcApexPieChart = ({ dataColors, tableData, title }: any) => {
    const PieEChartColors = getChartColorsArray(dataColors);
    var labels = map(tableData, 'name')
    var data = map(tableData, 'value')
    const series = data
    const options: any = {
        chart: {
            width: '100%',
            type: 'pie',
        },
        labels: labels,
        // theme: {
        //     monochrome: {
        //         enabled: true
        //     }
        // },
        color: PieEChartColors,
        series: [
            {
                name: "Specialtie",
                type: "pie",
                radius: "55%",
                center: ["50%", "60%"],
                data: data,
                itemStyle: {
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: "rgba(0, 0, 0, 0.5)",
                    },
                },
            },
        ],
        plotOptions: {
            pie: {
                dataLabels: {
                    offset: -5
                }
            }
        }, dataLabels: {
            formatter(val: any, opts: any) {
                const name = opts.w.globals.labels[opts.seriesIndex]
                return [name, val.toFixed(1) + '%']
            }
        },
        title: {
            text: title
        },
        legend: {
            show: false
        }
    }
    return (
        <div id="chart">
            <ReactApexChart options={options} series={series} type="pie" height={375} />
        </div>

    )
}
export default HcApexPieChart;