import React from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "Components/Common/ChartDynamicColor";
import { map } from "lodash";

const LineChart = ({dataColors, tableData}:any) => {
  const lineEChartColors = getChartColorsArray(dataColors);
  var valueOne = map(tableData, 'valueOne')
  var labels = map(tableData, 'label')
  const options = {
    tooltip: {
      trigger: "axis",
    },
    grid: {
      zlevel: 0,
      x: 50,
      x2: 50,
      y: 30,
      y2: 30,
      borderWidth: 0,
    },
    xAxis: {
      type: "category",
      data: labels,
      axisLine: {
        lineStyle: {
          color: "#8791af",
        },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: "#8791af"
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgba(166, 176, 207, 0.1)"
        }
      }
    },
    series: [
      {
        data: valueOne,
        type: "line",
      },
    ],
    color: lineEChartColors,
    textStyle: {
      color: ["#8791af"],
    },
  };
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: 215 }} option={options}/>
    </React.Fragment>
  );
};
export default LineChart;
