import { createSlice } from "@reduxjs/toolkit";
import { getEmployee, AddEmployee } from "./thunk";

export const initialState = {
    employee: [],
    loading: true
}
const EmployeesSlice = createSlice({
   
    name: 'EmployeesSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEmployee.fulfilled, (state: any, action: any) => {
            state.employee = action.payload;
            state.loading = true
        });
        builder.addCase(getEmployee.rejected, (state: any, action: any) => {
            state.error = action.payload ? action.payload?.error : null;
        })
        builder.addCase(AddEmployee.fulfilled, (state: any, action: any) => {
            state.employee.push(action.payload);

        });
        builder.addCase(AddEmployee.rejected, (state: any, action: any) => {
            state.error = action.payload || null;
        })
    }
})

export default EmployeesSlice.reducer;