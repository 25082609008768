import DataTable from "Components/DataTable"
import React from "react"
import { Table, Card, CardBody, CardHeader, Row, Col } from "reactstrap"

const TerminationTracker = () => {
    const tableOneColumns = [
        { label: "Client Name" }, { label: "Line of Business" }, { label: "Affiliation" }, { label: "Volume Monthly" },
        { label: "Volume Yearly" }, { label: "State" }, { label: "Specialty" }, { label: "Term Code" },
        { label: "Start Date" }, { label: "Date Term Notice Rcvd" }, { label: "Term Date" }, { label: "Notes/Comments" }
    ]
    const tableOneData = [
        { col: ["Samwell Surgical, LLC", "MedTek – ASC", "", 150, 1500, "NJ", "Surgery", "NA", "6/16/23", "9/30/23", "9/30/23", "No issues with coding"] },
        { col: ["Western Carolina Surgical", "MedTek – ASC", "", 500, 6000, "NC", "Surgery", "NA", "10/6/21", "9/30/23", "9/30/23", "No issues with coding"] }
    ]
    const tableTwoColumns = [
        { label: "  Termination Codes/Reasons" }, { label: "" }, { label: "" }
    ]
    const tableTwoData = [
        { col: ["1 - Management company took over operations", "2 -Client brought coding in-house", "3 -Switched to another company"] }
    ]


    return (
        <React.Fragment>
            <Card >
                <CardHeader className="bg-transparent border-bottom text-uppercase h6">Termination Tracker – No Terminations </CardHeader>
                <CardBody>
                    {/* <Table className="table table-sm mb-1">
                        <thead>
                            <tr>
                                {
                                    tableOneColumns.map((r) => {
                                        return <th>{r.label}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableOneData.map((r) => {
                                    return <tr>
                                        {r.col.map((c)=>{
                                            return<td>{c}</td>
                                        })}
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table> */}
                    <Row>
                        <DataTable tableColumns={tableOneColumns} tableData={tableOneData} />
                    </Row>
                    <Row className="mt-5">
                        <DataTable tableColumns={tableTwoColumns} tableData={tableTwoData} />
                    </Row>
                    {/* <Table className="table table-sm mb-1 mt-5">
                        <thead>
                            <tr>
                                {
                                    tableTwoColumns.map((r) => {
                                        return <th>{r.label}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableTwoData.map((r) => {
                                    return <tr>
                                        {r.col.map((c) => {
                                            return <td>{c}</td>
                                        })}
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table> */}
                </CardBody>
            </Card>
        </React.Fragment>

    )
}
export default TerminationTracker;