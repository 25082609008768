import React from "react";
import { Row } from "reactstrap";
import Anesthesia from "./AnesthesiaAvg";
import ClientCategories from "./ClientCategories";
import ClientsSummary from "./ClientsSummary";
import GeographicLocations from "./GeographicLocations";
import ManPower from "./ManPower";
import Pipeline from "./Pipeline";
import QualityAuditScores from "./QualityAuditScores";
import TerminationTracker from "./TerminationTracker";

const RCMReport = () => {
    document.title = "Apex Charts | Skote - React Admin & Dashboard Template";

    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <ManPower />
                    </Row>
                    <Row>
                        <Anesthesia />
                    </Row>

                    {/* <Row>
                        <Col lg={7}>
                        <VolumeSnapshot />
                        </Col>
                        <Col lg={5}>
                            <BarChart dataColors='["--bs-success","--bs-primary", "--bs-danger","--bs-info", "--bs-warning"]' />
                            <Apaexlinecolumn dataColors='["--bs-danger","--bs-primary", "--bs-success"]' />
                        </Col>
                    </Row> */}
                    {/* <Row>
                        <ClientCategories />
                    </Row>
                    <Row>
                        <GeographicLocations />
                    </Row>
                    <Row>
                        <QualityAuditScores />
                    </Row>
                    <Row>
                        <ClientsSummary />
                    </Row>
                    <Row>
                        <Pipeline />
                    </Row>
                    <Row>
                        <TerminationTracker />
                    </Row> */}
                </div>
            </div>
        </React.Fragment>
    )
}

export default RCMReport
