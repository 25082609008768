import React, { Fragment } from 'react';
import Chart from 'react-google-charts';
import { Card, CardBody, CardHeader } from 'reactstrap';
import './OrganizationChartDemo.css';

export default function SupportTeam() {
    const data = [
        [
            {
                v: "nimble Executive Team Murali Krishna R Managing Director",
                f: '<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>',
            },
            "",
            "The President",
        ],
        ["HR & Others -19", "<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ["<div>IT Infra /Software</div><div>Development-28</div>", "<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ["<div>Accounts, Finance,</div><div>Admin & Facilities - 28</div>", "<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ["<div>Anuradha.D</div><div>Vice President -1</div>", "HR & Others -19", ""],
        ["<div>Sr.Manager – 1</div><div>Manager – 1</div>", "<div>Anuradha.D</div><div>Vice President -1</div>", ""],
        ["<div>HR BP – 2</div><div>HR Executives – 8</div>", "<div>Sr.Manager – 1</div><div>Manager – 1</div>", ""],
        ["<div>Corporate</div><div>Communications</div><div>& Others – 6</div>", "<div>HR BP – 2</div><div>HR Executives – 8</div>", ""],
        ["<div>Mohan G</div><div>Vice President - 1</div>", "<div>IT Infra /Software</div><div>Development-28</div>", ""],
        ["Manager – 1", "<div>Mohan G</div><div>Vice President - 1</div>", ""],
        ["Team Lead – 1", "Manager – 1", ""],
        ["S/W Engineers – 7", "Team Lead – 1", ""],
        ["<div>Kamal K</div><div>Head – 1</div>", "<div>Mohan G</div><div>Vice President - 1</div>", ""],
        ["Asst. Manager –1", "<div>Kamal K</div><div>Head – 1</div>", ""],
        ["System Admins – 12", "Asst. Manager –1", ""],
        ["Jr. System Admins- 12", "System Admins – 12", ""],

        ["<div>Suresh.G</div><div>Admin & Facilities - 28</div>", "<div>Accounts, Finance,</div><div>Admin & Facilities - 28</div>", ""],
        ["<div>Director-Infra & </div><div>Facilities–1</div><div>Sr.Engineer - 1</div>", "<div>Suresh.G</div><div>Admin & Facilities - 28</div>", ""],
        ["<div>Sr.Manager – 1</div><div>Sr.Specialist A/C - 1</div>", "<div>Director-Infra & </div><div>Facilities–1</div><div>Sr.Engineer - 1</div>", ""],
        ["Executives – 2", "<div>Sr.Manager – 1</div><div>Sr.Specialist A/C - 1</div>", ""],
        ["<div>Security & Support </div><div>Staff –21</div>", "Executives – 2", ""],



    ];

    const options = {
        allowHtml: true,
    };
    return (
        <Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Card className='overflow'>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h4">Organizational Chart - Support Teams</CardHeader>
                        <CardBody>
                            <Chart
                                chartType="OrgChart"
                                data={data}
                                options={options}
                                width="100%"
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Fragment>

    )
}
