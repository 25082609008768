import React from "react";
import ReactApexChart from "react-apexcharts";

const Apaexlinecolumn = ({series, options}:any) => {
  
  return (
    <ReactApexChart options={options} series={series} type="bar" height={500} />
  );
};

export default Apaexlinecolumn;
