import React, { Fragment } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import Paper from "@material-ui/core/Paper";

export default function DataTable({ tableColumns, tableData }: any) {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Fragment>
            <Table className="table table-sm mb-1">
                <TableHead>
                    <TableRow>
                        {tableColumns.map((header) => {
                            return <TableCell style={{ fontWeight: 'bold' }}>{header.label}</TableCell>
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => (
                            <TableRow>
                                {row.col.map((v) => {
                                    return <TableCell>{v}</TableCell>
                                })}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            {
                tableData.length > 5 && <TablePagination
                    labelRowsPerPage={false}
                    rowsPerPageOptions={[]}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            }
        </Fragment>
    );
}
