
import React from "react"
import { Table, Card, CardBody, CardHeader, Row, Col } from "reactstrap"
// import RCMLineColumnArea from "../RCMColumnchart"
// import SnapshotBarChart from "../SnapshotBarChart"
// import BarChart from "../barchart"
// import LineChart from "Components/Charts/linechart"
import Apaexlinecolumn from "Components/Charts/apaexlinecolumn"
import getChartColorsArray from "Components/Common/ChartDynamicColor"
import LineChart from "Components/Charts/linechart"


const Anesthesia = () => {
    const apaexlineColumnColors = getChartColorsArray('["--bs-danger","--bs-primary", "--bs-success"]');
    const months = [
        "Apr-23",
        "May-23",
        "Jun-23",
        "Jul-23",
        "Aug-23",
        "Sep-23",
        "Oct-23",

    ]
    const anesthesiaEmpSeries = [
        {
            name: "Charge Claims",
            data: [58, 73, 82, 80, 82, 79, 83],
        },
        {
            name: "Payments",
            data: [268, 258, 202, 195, 200, 261, 296],
        },
        {
            name: "AR ",
            data: [20, 34, 30, 23, 22, 28, 21],
        },
    ];

    const anesthesiaOptions = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "75%",
                endingShape: "rounded",
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: any) {
                return val
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },

        colors: apaexlineColumnColors,
        xaxis: {
            categories: months
        },
        yaxis: {
            title: {
                text: "Avg Monthly Production ",
            },
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return val + "Members";
                },
            },
        },
    };

    const chargesClaims = [
        { label: "Apr-23", valueOne: 75 },
        { label: "May-23", valueOne: 80 },
        { label: "Jun-23", valueOne: 82 },
        { label: "Jul-23", valueOne: 89 },
        { label: "Aug-23", valueOne: 90 },
        { label: "Sep-23", valueOne: 93 },
        { label: "Oct-23", valueOne: 76 },
    ]
    const payments = [
        { label: "Apr-23", valueOne: '' },
        { label: "May-23", valueOne: 99.52 },
        { label: "Jun-23", valueOne: 99.64 },
        { label: "Jul-23", valueOne: 99.69 },
        { label: "Aug-23", valueOne: 99.44 },
        { label: "Sep-23", valueOne: 99.72 },
        { label: "Oct-23", valueOne: '' },
    ]
    const Ar = [
        { label: "Apr-23", valueOne: 99.05 },
        { label: "May-23", valueOne: 96.08 },
        { label: "Jun-23", valueOne: 95.13 },
        { label: "Jul-23", valueOne: 93.9 },
        { label: "Aug-23", valueOne: 98.6 },
        { label: "Sep-23", valueOne: 98.48 },
        { label: "Oct-23", valueOne: 98.69 },
    ]

    return (
        <React.Fragment>
            <Row>
                <Col >
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Anesthesia-Average Monthly Production (No. of Patient Accounts/Day)
                        </CardHeader>
                        <CardBody>
                            <Apaexlinecolumn series={anesthesiaEmpSeries} options={anesthesiaOptions} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h4 App">Anesthesia Audit Score </CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Avg Charges/Claims</CardHeader>
                        <CardBody>
                            <LineChart dataColors='["--bs-danger","--bs-primary", "--bs-success"]' tableData={chargesClaims} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Avg Payments</CardHeader>
                        <CardBody>
                            <LineChart dataColors='["--bs-danger","--bs-primary", "--bs-success"]' tableData={payments} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Avg AR </CardHeader>
                        <CardBody>
                            <LineChart dataColors='["--bs-danger","--bs-primary", "--bs-success"]' tableData={Ar} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    )
}
export default Anesthesia;