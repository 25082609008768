import React from "react";
import { Col, Row } from "reactstrap";
import GeographicLocations from "./GeographicLocations";
import MedicalCoding from "./MedicalCodingChart";
import QualityAuditScores from "./New/QualityAuditScores";
import SpecialityBasedChart from "./New/SpecialityBasedVolume";
import ClientsSummary from "./NewClientsSummary";
import Pipeline from "./Pipeline";
import AverageProductionFTE from "./Sample/AverageProductionFTE";
import Sample from "./Sample/Sample";
import TerminationTracker from "./TerminationTracker";




const CodingReport = () => {
    document.title = "Apex Charts | Skote - React Admin & Dashboard Template";
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Sample />
                    </Row>
                    {/* <Row >
                        <RcmCoding />
                    </Row> */}
                    {/* <Row >
                        <ModularCoding />
                    </Row> */}
                    {/* <Row>
                        <SpecialityBarChart />
                    </Row> */}
                    {/* <Row>
                        <Col>
                            <AvgProductionFTE />
                        </Col>
                        <Col lg={3}>
                            <RCMAvgProduction />
                        </Col>
                        <Row>
                        <Col >
                            <ClientSummary />
                        </Col>
                        </Row>
                       
                    </Row> */}
                    <Row>
                        <Col>
                            <QualityAuditScores />
                        </Col>
                    </Row>
                    <Row>
                        <AverageProductionFTE />
                    </Row>

                    <Row>
                        <Col>
                            <SpecialityBasedChart />
                        </Col>
                    </Row>
                    <Row>
                        <GeographicLocations />
                    </Row>
                    <Row>
                        <Col>
                            <ClientsSummary />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Pipeline />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TerminationTracker />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <MedicalCoding />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CodingReport
