import DataTable from "Components/DataTable"
import React from "react"
import { Table, Card, CardBody, CardHeader } from "reactstrap"

const ClientsSummary = () => {
    const tableColumns = [{ label: "Facility" }, { label: "Line of Business" }, { label: "Start Date" }, { label: "ST" },
    { label: "Specialty" }, { label: "Mo Vol" }, { label: "Annualized Monthly Revenue" }, { label: "Fee" },
    { label: "Who had business prior?" }, { label: "Referral Source Type" }, { label: "Referral Source Name" },
    { label: "Affiliations" }, { label: "Notes" }]
    const tableData = [
        { col: ["Jupiter Outpatient Surgery Center", "MedTek - ASC", "09/13/23", "Florida", "Surgery", 70, , "", "", "", "", "", "Independent", "Regular"] },
        { col: ["Sugarland Surgery Cente", "MedTek – ASC", "09/05/23", "Texas", "Surgery", 200, "", "", "", "", "", "HCA", "HCA Coverage"] },
        { col: ["Brownsville Surgery Center", "MedTek – ASC", "09/05/23", "Texas", "Surgery", 200, "", "", "", "", "", "HCA", "HCA Coverage"] },
        { col: ["Careos Surgery Center", "MedTek – ASC", "09/05/23", "Texas", "Surgery", 200, "", "", "", "", "", "HCA", "HCA Coverage"] }
    ]

    return (
        <React.Fragment>
            <Card >
                <CardHeader className="bg-transparent border-bottom text-uppercase h6">New Clients Summary (Live first 30 days)</CardHeader>
                <CardBody>
                    {/* <Table className="table table-sm mb-1">
                        <thead>
                            <tr>
                                {
                                    tableColumns.map((r) => {
                                        return <th>{r}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData.map((r) => {
                                    return <tr>
                                        {r.col.map((c)=>{
                                            return<td>{c}</td>
                                        })}
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table> */}

                    <DataTable tableColumns={tableColumns} tableData={tableData} />
                </CardBody>
            </Card>
        </React.Fragment>

    )
}
export default ClientsSummary;