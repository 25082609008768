import React, { Fragment, useState } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import { Card, CardBody, CardHeader, CardTitle } from 'reactstrap';
import Chart from 'react-google-charts';
import './OrganizationChartDemo.css';

export default function OrgChart() {
    const data = [
        [
            {
                v: "nimble Executive Team Murali Krishna R Managing Director",
                f: '<div> Murali Krishna R </div><div>Managing Director</div>',
            },
            "",
            "The President",
        ],
        ["Production Teams", "<div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ["Support Teams", "<div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ["Healthcare Documentation - 286", "Production Teams", ""],
        ["Medical coding - 193", "Production Teams", ""],
        ["RCM - 329", "Production Teams", ""],
        ["HR & Others - 19", "Support Teams", ""],
        ["IT/Software Development -10", "Support Teams", ""],
        ["Accounts, Finance, Admin & Facilities - 28", "Support Teams", ""],
        ["IT Infra - 16", "Support Teams", ""],
        ['<table class="tablealign"><thead><tr><th class="tablealign">Line of Business</th><th class="tablealign">COGs</th><th class="tablealign">Support</th><th class="tablealign">Lead/QA</th></tr></thead><tbody><tr><td class="tablealign">Medtek</td><td class="tablealign">119</td><td class="tablealign">13</td><td colSpan="2" >15</td></tr><tr><td class="tablealign">nimble</td><td class="tablealign">40</td><td class="tablealign">6</td></tr></tbody></table>', "Medical coding - 193", ""],
        ['<table class="tablealign"><thead><tr><th class="tablealign">Line of Business</th><th class="tablealign">COGs</th><th class="tablealign">Lead/QA</th></tr></thead><tbody><tr><td class="tablealign" ><div >Anesthesia</div><div >ASC</div><div >Professional</div></td><td><div>40</div><div>202</div><div >38</div></td><td colSpan="2" class="tablealign">34</td></tr><tr><td class="tablealign">Medtek</td><td class="tablealign">119</td></tr></tbody></table>', "RCM - 329", ""],

    ];

    const options = {
        allowHtml: true,
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h4">Organizational Chart</CardHeader>
                        <CardBody>
                            <Chart
                                chartType="OrgChart"
                                data={data}
                                options={options}
                                width="100%"
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </React.Fragment>
    )
}
