import { createAsyncThunk } from "@reduxjs/toolkit";

import {
    getEmployee as getEmployeeApi,
    addNewEmployee as addNewEmployeeAPi
} from '../../helpers/fakebackend_helper'

export const getEmployee = createAsyncThunk("Org/getEmployee", async (event: any) => {
    try {
        const response = getEmployeeApi(event.page, event.size);
        return response;
    } catch (err) {
        return err;
    }
});

export const AddEmployee = createAsyncThunk("Org/AddEmployee", async (event: any) => {
    try {
        const response = addNewEmployeeAPi(event);
        return response;
    } catch (err) {
        return err;
    }
});