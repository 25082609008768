import React from "react"
import { Row, Col, Card, CardBody, CardHeader } from "reactstrap"
import HcBarChart from "../HcBarChart"
import { map, find, pick, keys } from "lodash";

const DProductionFTE = () => {
    const tableColumns = ["Oct 2023", "Sep 2023", "Aug 2023", "Jul 2023", "June 2023", "May 2023 ", "Apr 2023",]
    const FTETotalData = [
        { label: "HDA", valueOne: 2105585, valueTwo: 2073525, valueThree: 2228302, valueFour: 1919959, valueFive: 2200726, valueSix: 2184172, valueSeven: 2679505 },
        { label: "HDP", valueOne: 1052164, valueTwo: 990239, valueThree: 1196444, valueFour: 1067537, valueFive: 1227459, valueSix: 1194917, valueSeven: 1149137 },
        { label: "HDQC", valueOne: 1791175, valueTwo: 1630460, valueThree: 1055599, valueFour: 1255445, valueFive: 1532580, valueSix: 1575077, valueSeven: 1575044 },
        { label: "HDAE", valueOne: 1148373, valueTwo: 1041364, valueThree: 1759564, valueFour: 1060851, valueFive: 1192442, valueSix: 1110750, valueSeven: 1121156 }

    ]
    const FTETotalState = {
        horizontal: true,
        height: "650",
        title: "",
        minValue: 200000
    }

    var model = {
        valueOne: null,
        valueTwo: null,
        valueThree: null,
        valueFour: null,
        valueFive: null,
        valueSix: null,
        valueSeven: null
    };
    var label = map(FTETotalData, 'label')
    var data1 = find(FTETotalData, function (o) { return o.label === "HDA"; });
    var data2 = find(FTETotalData, function (o) { return o.label === "HDP"; });
    var data3 = find(FTETotalData, function (o) { return o.label === "HDQC"; });
    var data4 = find(FTETotalData, function (o) { return o.label === "HDAE"; });
    var result1 = pick(data1, keys(model));
    var result2 = pick(data2, keys(model));
    var result3 = pick(data3, keys(model));
    var result4 = pick(data4, keys(model));
    var HDAData = Object.values(result1)
    var HDPData = Object.values(result2)
    var HDQCData = Object.values(result3)
    var HDAEData = Object.values(result4)

    const FTETotalSeries = [
        {
            name: label[0],
            data: HDAData,
        },
        {
            name: label[1],
            data: HDPData,
        },
        {
            name: label[2],
            data: HDQCData,
        },
        {
            name: label[3],
            data: HDAEData,
        },
    ]

    const FTEEmployees = [
        { label: "HDA", valueOne: 115, valueTwo: 112, valueThree: 116, valueFour: 121, valueFive: 118, valueSix: 121, valueSeven: 127 },
        { label: "HDP", valueOne: 33, valueTwo: 30, valueThree: 44, valueFour: 32, valueFive: 29, valueSix: 30, valueSeven: 32 },
        { label: "HDQC", valueOne: 43, valueTwo: 41, valueThree: 29, valueFour: 33, valueFive: 34, valueSix: 34, valueSeven: 37 },
        { label: "HDAE", valueOne: 43, valueTwo: 43, valueThree: 41, valueFour: 42, valueFive: 42, valueSix: 43, valueSeven: 44 }

    ]
    const FTEEmployeesState = {
        horizontal: true,
        height: "600",
        title: "",
        minValue: 0
    }

    var Empmodel = {
        valueOne: null,
        valueTwo: null,
        valueThree: null,
        valueFour: null,
        valueFive: null,
        valueSix: null,
        valueSeven: null
    };
    var Emplabel = map(FTEEmployees, 'label')
    var Empdata1 = find(FTEEmployees, function (o) { return o.label === "HDA"; });
    var Empdata2 = find(FTEEmployees, function (o) { return o.label === "HDP"; });
    var Empdata3 = find(FTEEmployees, function (o) { return o.label === "HDQC"; });
    var Empdata4 = find(FTEEmployees, function (o) { return o.label === "HDAE"; });
    var Empresult1 = pick(Empdata1, keys(Empmodel));
    var Empresult2 = pick(Empdata2, keys(Empmodel));
    var Empresult3 = pick(Empdata3, keys(Empmodel));
    var Empresult4 = pick(Empdata4, keys(Empmodel));
    var EmpHDAData = Object.values(Empresult1)
    var EmpHDPData = Object.values(Empresult2)
    var EmpHDQCData = Object.values(Empresult3)
    var EmpHDAEData = Object.values(Empresult4)

    const FTEEmployeesSeries = [
        {
            name: Emplabel[0],
            data: EmpHDAData,
        },
        {
            name: Emplabel[1],
            data: EmpHDPData,
        },
        {
            name: Emplabel[2],
            data: EmpHDQCData,
        },
        {
            name: Emplabel[3],
            data: EmpHDAEData,
        },
    ]
    const FTEAvarage = [
        { label: "HDA", valueOne: 18309, valueTwo: 18514, valueThree: 19210, valueFour: 15867, valueFive: 18650, valueSix: 18051, valueSeven: 21098 },
        { label: "HDP", valueOne: 31884, valueTwo: 33008, valueThree: 27192, valueFour: 33361, valueFive: 42236, valueSix: 39831, valueSeven: 35911 },
        { label: "HDQC", valueOne: 41655, valueTwo: 39767, valueThree: 36400, valueFour: 38043, valueFive: 45076, valueSix: 46326, valueSeven: 42569 },
        { label: "HDAE", valueOne: 26706, valueTwo: 24218, valueThree: 42916, valueFour: 25258, valueFive: 28391, valueSix: 25831, valueSeven: 25480 }

    ]
    const FTEAvarageState = {
        horizontal: true,
        height: "600",
        title: "",
        minValue: 0
    }

    var Avgmodel = {
        valueOne: null,
        valueTwo: null,
        valueThree: null,
        valueFour: null,
        valueFive: null,
        valueSix: null,
        valueSeven: null
    };
    var Avglabel = map(FTEAvarage, 'label')
    var Avgdata1 = find(FTEAvarage, function (o) { return o.label === "HDA"; });
    var Avgdata2 = find(FTEAvarage, function (o) { return o.label === "HDP"; });
    var Avgdata3 = find(FTEAvarage, function (o) { return o.label === "HDQC"; });
    var Avgdata4 = find(FTEAvarage, function (o) { return o.label === "HDAE"; });
    var Avgresult1 = pick(Avgdata1, keys(Avgmodel));
    var Avgresult2 = pick(Avgdata2, keys(Avgmodel));
    var Avgresult3 = pick(Avgdata3, keys(Avgmodel));
    var Avgresult4 = pick(Avgdata4, keys(Avgmodel));
    var AvgHDAData = Object.values(Avgresult1)
    var AvgHDPData = Object.values(Avgresult2)
    var AvgHDQCData = Object.values(Avgresult3)
    var AvgHDAEData = Object.values(Avgresult4)

    const FTEAvarageSeries = [
        {
            name: Avglabel[0],
            data: AvgHDAData,
        },
        {
            name: Avglabel[1],
            data: AvgHDPData,
        },
        {
            name: Avglabel[2],
            data: AvgHDQCData,
        },
        {
            name: Avglabel[3],
            data: AvgHDAEData,
        },
    ]
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Total Lines Processed(Production FTE)</CardHeader>
                        <CardBody>
                            <HcBarChart dataColors='["--bs-primary","--bs-success","--bs-danger","--bs-secondary"]' tableColumns={tableColumns} dataseries={FTETotalSeries} state={FTETotalState} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">No of Employees(Production FTE) </CardHeader>
                        <CardBody>
                            <HcBarChart dataColors='["--bs-primary","--bs-success","--bs-danger","--bs-secondary"]' tableColumns={tableColumns} dataseries={FTEEmployeesSeries} state={FTEEmployeesState} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Average FTELines(Production FTE)</CardHeader>
                        <CardBody>
                            <HcBarChart dataColors='["--bs-primary","--bs-success","--bs-danger","--bs-secondary"]' tableColumns={tableColumns} dataseries={FTEAvarageSeries} state={FTEAvarageState} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>

    )
}
export default DProductionFTE;