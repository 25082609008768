import React, { Fragment } from 'react';
import Chart from 'react-google-charts';
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import '../OrgCharts/OrganizationChartDemo.css';

export default function MedicalCoding() {
    const data = [
        [
            {
                v: "nimble Executive Team Murali Krishna R Managing Director",
                f: '<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>',
            },
            "",
            "The President",
        ],
        ["<div>Vice President -1</div><div>Pravesh Gupta</div>", "<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ["<div>Sr.Manager ACT - 1</div>", "<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ["<div>Training & Audit – 2</div>", "<div>Sr.Manager ACT - 1</div>", ""],
        ["<div>Director – 1</div><div>Ravi Kapilavai</div>", "<div>Vice President -1</div><div>Pravesh Gupta</div>", ""],
        ["<div>Asst. Managers – 3</div>", "<div>Director – 1</div><div>Ravi Kapilavai</div>", ""],
        ["<div>TL/QA – 7</div>", "<div>Director – 1</div><div>Ravi Kapilavai</div>", ""],

        ['<div><table class="tablealign" ><thead><tr><th class="tablealign" >FTE Process</th><th class="tablealign">Medtek</th><th class="tablealign">Nimble</th></tr></thead><tbody><tr><td class="tablealign">Medical Coders</td><td class="tablealign">69</td><td class="tablealign">3</td></tr><tr><td class="tablealign" >Quality Controllers</td><td class="tablealign">17</td><td class="tablealign">4</td></tr><tr><td class="tablealign">Account Experts</td><td class="tablealign">33</td><td class="tablealign">33</td></tr><tr><td class="tablealign fw-bold">Total</td><td class="tablealign fw-bold">119</td><td class="tablealign fw-bold">40</td></tr></tbody></table></div>', "<div>Asst. Managers – 3</div>", ""],
        ['<div><table class="tablealign"><thead><tr><th class="tablealign">FTE Process</th><th>Medtek</th><th class="tablealign">Nimble</th></tr></thead><tbody><tr><td class="tablealign">Operations Executive</td><td class="tablealign" >13</td><td class="tablealign">6</td></tr></tbody></table>', "<div>TL/QA – 7</div>", ""],



    ];

    const options = {
        allowHtml: true,
    };
    return (
        <Fragment>
            <Row>
                <Card >
                    <CardHeader className="bg-transparent border-bottom text-uppercase h6">Medical Coding</CardHeader>
                    <CardBody>
                        <Chart
                            chartType="OrgChart"
                            data={data}
                            options={options}
                            width="100%"
                        />
                    </CardBody>
                </Card>
            </Row>
        </Fragment>

    )
}
