import React, { Fragment } from 'react';
import Chart from 'react-google-charts';
import { Card, CardBody, CardHeader } from 'reactstrap';
import './OrganizationChartDemo.css';

export default function ProductionTeam() {
    const data = [
        [
            {
                v: "nimble Executive Team Murali Krishna R Managing Director",
                f: '<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>',
            },
            "",
            "The President",
        ],
        ['<div style="display:flex;"><div><table border=1><tr><td><span class="textalign">Sr. Manager – Niranjan Muppuri & Team –5</span> </td><td> <span class="textalign">Audit, Certification & Training(ACT)</span></td></tr></table></div><div><table class="tablealign" class="w3-table w3-striped"><thead><tr><th>Medical Coding</th></tr></thead><tbody><tr class="tablealign"><td>Pravesh Gupta Vice President -1</td><tr class="tablealign"><td>Director - 1</td></tr><td class="tablealign">Asst.Manager - 3, Team Leaders-5</td></tr><tr class="tablealign"><td>Medical Coders, QualityControllers & Account Experts -159</td><tr><td claa>Account Coordinates-19</td></tr></tr></tbody></table></div></div>', "<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ['<div><table class="tablealign"><thead><tr><th class="tablealign">LOB</th><th>CGOs</th></tr></thead><tbody><tr ><td class="tablealign">Medtek</td><td class="tablealign">119</td></tr><tr class="tablealign"><td class="tablealign">Anesthesia</td><td>13</td></tr><tr><td class="tablealign">ASC</td><td class="tablealign">23</td></tr><tr><td class="tablealign">Professional</td><td class="tablealign">4</td></tr><tr><td class="fw-bold">Total</td><td class="tablealign">159</td></tr></tr></tbody></table></div></div>', '<div style="display:flex;"><div><table border=1><tr><td><span class="textalign">Sr. Manager – Niranjan Muppuri & Team –5</span> </td><td> <span class="textalign">Audit, Certification & Training(ACT)</span></td></tr></table></div><div><table class="tablealign" class="w3-table w3-striped"><thead><tr><th>Medical Coding</th></tr></thead><tbody><tr class="tablealign"><td>Pravesh Gupta Vice President -1</td><tr class="tablealign"><td>Director - 1</td></tr><td class="tablealign">Asst.Manager - 3, Team Leaders-5</td></tr><tr class="tablealign"><td>Medical Coders, QualityControllers & Account Experts -159</td><tr><td claa>Account Coordinates-19</td></tr></tr></tbody></table></div></div>', ""],
        ['<div style="display:flex;"><div><table border=1><tr><td><span class="textalign">Director – Pradyut Jana & Team  9</span> </td><td> <span class="textalign">Audit, Certification & Training(ACT)</span></td></tr></table></div><div><table class="tablealign"><thead><tr><th>Healthcare Documentation</th></tr></thead><tbody><tr class="tablealign"><td>Mayur Damani Sr. Vice President -1</td><tr class="tablealign"><td>Directors - 3</td></tr><tr class="tablealign"><td>Managers - 4</td></tr><tr class="tablealign"><td >Asst. Managers – 5 ,Team Leaders – 11</td><tr class="tablealign"><td>HDA/HDP/HDAE/HDE/HDQC – 226 </td></tr><tr><td>Account Coordinators/– 27</td></tr></tr></tbody></table>', "<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ['<div style="display:flex;"><div><table border=1><tr><td><span class="textalign">AM – Team - 12</span> </td><td> <span class="textalign">Audit, Certification & Training(ACT)</span></td></tr></table></div><div><table class="tablealign"><thead><tr><th class="tablealign">Revenue Cycle Management</th></tr></thead><tbody><tr class="tablealign"><td>Nambirajan G Sr. Vice President – 1</td><tr class="tablealign"><td>Satish Kumar T Vice President – 1</td></tr><tr class="tablealign"><td>Director RCM-2</td></tr><tr class="tablealign"><td>Dy.Manager/ AM -7 , Team Lead-11</td><tr><td>Charges -53</td></tr><tr><td>Claims - 33</td></tr><tr><td>Payments -67</td></tr><tr><td>AR - 82</td></tr><tr><td>Analytics - 31</td></tr><tr><td>Refund -15</td></tr><tr><td>Demos - 3</td></tr><tr><td>Insurance -5</td></tr><tr><td>Patient A/C - 3</td></tr><tr><td>Merged Care - 3</td></tr></tr></tbody></table></div></div>', "<div>nimble Executive Team</div><div> Murali Krishna R </div><div>Managing Director</div>", ""],
        ['<div><table class="tablealign";><thead><tr class="tablealign"><th class="tablealign">Team/LOB</th><th class="tablealign">ASC</th><th class="tablealign">Anesthesia</th><th class="tablealign">Professional</th><th class="tablealign">Medek</th></tr></thead>'
            + '<tbody><tr><td class="tablealign">Account Receivable</td><td class="tablealign">44</td><td class="tablealign">17</td><td class="tablealign">11</td><td class="tablealign">10</td></tr>'
            + '<tr><td class="tablealign">Analytics</td><td class="tablealign">31</td><td class="tablealign">0</td><td class="tablealign">0</td><td class="tablealign">0</td></tr>'
            + '<tr><td class="tablealign">Charge Posting</td><td class="tablealign">22</td><td class="tablealign">15</td><td class="tablealign">13</td><td class="tablealign">3</td></tr>'
            + '<tr><td class="tablealign">Claims</td><td class="tablealign">30</td><td class="tablealign">0</td><td class="tablealign">3</td><td class="tablealign">0</td></tr>'
            + '<tr><td class="tablealign">Refunds</td><td class="tablealign">15</td><td class="tablealign">0</td><td class="tablealign">0</td><td class="tablealign">0</td></tr>'
            + '<tr><td class="tablealign">Demographic Entry</td><td class="tablealign">2</td><td class="tablealign">1</td><td class="tablealign">0</td><td class="tablealign">0</td></tr>'
            + '<tr><td class="tablealign">Insurance Verification</td><td class="tablealign">4</td><td class="tablealign">0</td><td class="tablealign">1</td><td class="tablealign">0</td></tr>'
            + '<tr><td class="tablealign">Mgd.Care</td><td class="tablealign">3</td><td class="tablealign">0</td><td class="tablealign">0</td><td class="tablealign">0</td></tr>'
            + '<tr><td class="tablealign">Patient A/C</td><td class="tablealign">3</td><td class="tablealign">0</td><td class="tablealign">0</td><td class="tablealign">0</td></tr>'
            + '<tr><td class="tablealign">Payment Posting</td><td class="tablealign">48</td><td class="tablealign">7</td><td class="tablealign">10</td><td class="tablealign">2</td></tr>'
            + '<tr class="fw-bold"><td class="tablealign">Total</td><td class="tablealign">202</td><td class="tablealign">40</td><td class="tablealign">38</td><td class="tablealign">15</td></tr></tr></tbody></table>',
            '<div style="display:flex;"><div><table border=1><tr><td><span class="textalign">AM – Team - 12</span> </td><td> <span class="textalign">Audit, Certification & Training(ACT)</span></td></tr></table></div><div><table class="tablealign"><thead><tr><th class="tablealign">Revenue Cycle Management</th></tr></thead><tbody><tr class="tablealign"><td>Nambirajan G Sr. Vice President – 1</td><tr class="tablealign"><td>Satish Kumar T Vice President – 1</td></tr><tr class="tablealign"><td>Director RCM-2</td></tr><tr class="tablealign"><td>Dy.Manager/ AM -7 , Team Lead-11</td><tr><td>Charges -53</td></tr><tr><td>Claims - 33</td></tr><tr><td>Payments -67</td></tr><tr><td>AR - 82</td></tr><tr><td>Analytics - 31</td></tr><tr><td>Refund -15</td></tr><tr><td>Demos - 3</td></tr><tr><td>Insurance -5</td></tr><tr><td>Patient A/C - 3</td></tr><tr><td>Merged Care - 3</td></tr></tr></tbody></table></div></div>', ""]



    ];

    const options = {
        allowHtml: true,
    };
    return (
        <Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Card className='overflow'>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h4">Organizational Chart - Production Teams</CardHeader>
                        <CardBody>
                            <Chart
                                chartType="OrgChart"
                                data={data}
                                options={options}
                                width="100%"
                            />
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Fragment>

    )
}
