import React, { useState } from "react"
import { Table, Card, CardBody, CardHeader, Row, Col } from "reactstrap"

const StaffingUpdates = () => {
    const tableColumns = [
        { label: [{ name: "Production FTE Summary", subLabel: [] }] },
        { label: [{ name: "Healthcare Documentation", subLabel: ["Laterals-COGs"] }] },
        { label: [{ name: "Medical Coding", subLabel: ["Laterals-COGs"] }] },
        { label: [{ name: "Medical Billing ", subLabel: ["Laterals-COGs", "Lateral–QA/Lead"] }] },
        { label: [{ name: "Support Teams", subLabel: ["Lateral-Lead"] }] },
        { label: [{ name: "Total Employees", subLabel: ["Laterals-COGs", "Lateral–QA/Lead"] }] }
    ]
    const tableData = [
        { col: ["Total # FTEs needed", 14, 15, 9, 2, 1, 38, 3] },
        { col: ["Total # new FTEs hired", 6, 8, 2, 1, 1, 16, 2] },
        { col: ["Total # FTEs to be hired", 10, 7, 7, 1, 0, 24, 1] },
        { col: ["Avg annual salary of new hires", "$3,202", "$6,266", "$6,108", "$12,852", "$4,764", "$5,641", "$8,808"] }
    ]

    return (
        <React.Fragment>
            <Card >
                <CardHeader className="bg-transparent border-bottom text-uppercase h4">Staffing Updates – Sep 2023</CardHeader>
                <CardBody>
                    <Table className="table table-sm mb-1">
                        <thead>
                            <tr>
                                {tableColumns.map((r) => {
                                    return <>
                                        {r.label.map((v) => {
                                            return <th>
                                                <tr>
                                                    <th colSpan={v.subLabel.length} style={{ textAlign: 'center' }}>{v.name}</th>
                                                </tr>
                                                <tr>
                                                    {v.subLabel.map((s) => {
                                                        return <th>
                                                            {s}
                                                        </th>
                                                    })}
                                                </tr>
                                            </th>
                                        })}
                                    </>
                                })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((r) => {
                                return <tr>
                                    {r.col.map((v) => {
                                        return  <td >{v}</td>
                                    })}
                                </tr>
                            })
                            }
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </React.Fragment>

    )
}
export default StaffingUpdates;