import DataTable from "Components/DataTable";
import React from "react"
import { Card, CardBody, CardHeader, Col, Table } from "reactstrap";
import HcApexPieChart from "./HcApexPieChart";

const HcTopClients = () => {
    const tableColumns = [
        { label: "Rank" }, { label: "Client Name" }, { label: "Line of Business" }, { label: "Affiliation" },
        { label: "Annualized Revenue" }, { label: "Monthly Volume" }, { label: "Pricing Per Line" },
        { label: "Client SatisfactionLevel (1-10)" }, { label: "Notes" }
    ]
    const data = [
        { name: "KOC2", value: 83076 },
        { name: "BOSM", value: 74076 },
        { name: "KSSH", value: 58123 },
        { name: "VVSC", value: 56635 },
        { name: "ROTH", value: 52443 },
        { name: "CUSC", value: 45165 },
        { name: "CAPC", value: 56635 },
        { name: "MCB2", value: 44975 },
        { name: "KOSC", value: 37486 },
    ]
    const tableData = [
        { col: [1, "Kansas Orthopedic Center - Touchworks(KOC2)", "Transcription", "Kansas Orthopedic Center", "", 83076, "", 10, ""] },
        { col: [2, "Beacon Orthopaedics & Sports Medicine (BOSM)", "Transcription", "Unknown", "", 74076, "", 10, ""] },
        { col: [3, "Kansas Spine & Specialty Hospital (KSSH)", "Transcription", "Unknown", "", 58123, "", 10, ""] },
        { col: [4, "Vail Valley Surgery Center (VVSC)", "Transcription", "Unknown", "", 56635, "", 10, ""] },
        { col: [5, "Rothman Orthopaedic Institute (ROTH)", "Transcription", "Rothman Clients", "", 52443, "", 10, ""] },
        { col: [6, "Central Utah Surgical Center (CUSC)", "Transcription", " HCA Healthcare", "", 45165, "", 10, ""] },
        { col: [7, "Capital City Surgery Center (CAPC)", "Transcription", "Unknown", "", 56635, "", 10, ""] },
        { col: [8, "Baptist Germantown Surgery Center (MCB2)", "Transcription", "Surgery Partners", "", 44975, "", 10, ""] },
        { col: [10, "Knoxville Orthopaedic Surgery Center (KOSC)", "Unknown", "", 37486, "", 10, ""] },
        // {
        //     rank: 1, name: "Kansas Orthopedic Center - Touchworks(KOC2)", business: "Transcription", affiliation: "Kansas Orthopedic Center",
        //     revenue: "", volume: 83076, pricing: " ", level: 10, notes: ""
        // },
        // {
        //     rank: 2, name: "Beacon Orthopaedics & Sports Medicine (BOSM)", business: "Transcription", affiliation: "Unknown",
        //     revenue: "", volume: 74076, pricing: " ", level: 10, notes: ""
        // },
        // {
        //     rank: 3, name: "Kansas Spine & Specialty Hospital (KSSH)", business: "Transcription", affiliation: "Unknown",
        //     revenue: "", volume: 58123, pricing: " ", level: 10, notes: ""
        // },
        // {
        //     rank: 4, name: "Vail Valley Surgery Center (VVSC) ", business: "Transcription", affiliation: "Unknown",
        //     revenue: "", volume: 56635, pricing: " ", level: 10, notes: ""
        // },
        // {
        //     rank: 5, name: "Rothman Orthopaedic Institute (ROTH)", business: "Transcription", affiliation: "Rothman Clients ",
        //     revenue: "", volume: 52443, pricing: " ", level: 10, notes: ""
        // },
        // {
        //     rank: 6, name: "Central Utah Surgical Center (CUSC) ", business: "Transcription", affiliation: " HCA Healthcare",
        //     revenue: "", volume: 45165, pricing: " ", level: 10, notes: ""
        // },
        // {
        //     rank: 7, name: "Capital City Surgery Center (CAPC) ", business: "Transcription", affiliation: "Unknown",
        //     revenue: "", volume: 45115, pricing: " ", level: 10, notes: ""
        // },
        // {
        //     rank: 8, name: "Baptist Germantown Surgery Center (MCB2)", business: "Transcription", affiliation: "Surgery Partners",
        //     revenue: "", volume: 44975, pricing: " ", level: 10, notes: ""
        // },
        // {
        //     rank: 9, name: "Oasis Hospital (OSIS) ", business: "Transcription", affiliation: "United Surgical Partners International ",
        //     revenue: "", volume: 42394, pricing: " ", level: 10, notes: ""
        // },
        // {
        //     rank: 10, name: "Knoxville Orthopaedic Surgery Center (KOSC)", business: "Transcription", affiliation: "Unknown",
        //     revenue: "", volume: 37486, pricing: " ", level: 10, notes: ""
        // },

    ]
    return (
        <React.Fragment>
            <Card >
                <CardHeader className="bg-transparent border-bottom text-uppercase h6">Top 10 Clients – by Revenue</CardHeader>
                <CardBody>

                    <HcApexPieChart dataColors='["--bs-danger","--bs-primary", "--bs-success"]' tableData={data} />
                    {/* <div className="table-responsive">
                        <Table className="table table-sm mb-1">
                            <thead>
                                <tr>
                                    {
                                        tableColumns.map((r) => {
                                            return <th>{r.label}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableData.map((r) => {
                                        return <tr>
                                            <td>{r.rank}</td>
                                            <td>{r.name}</td>
                                            <td>{r.business}</td>
                                            <td>{r.affiliation}</td>
                                            <td>{r.revenue}</td>
                                            <td>{r.volume}</td>
                                            <td>{r.pricing}</td>
                                            <td>{r.level}</td>
                                            <td>{r.notes}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </div> */}

                    {/* <DataTable tableColumns={tableColumns} tableData={tableData} /> */}
                </CardBody>
            </Card>
        </React.Fragment>

    )
}

export default HcTopClients
