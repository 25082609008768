import DataTable from "Components/DataTable";
import React from "react"
import { Card, CardBody, CardHeader, Table } from "reactstrap";

const HcPipelineSigned = () => {
    const tableColumns = [
        { label: "Sl.No" }, { label: "Facility" }, { label: "Line of Business –Transcription" }, { label: "Expected Start Date " },
        { label: "State" }, { label: "Audit(Y/N)" }, { label: "Specialty" },
        { label: "Volume (cases per month)" }, { label: "New/ Existing " }, { label: "Affiliation" }, { label: "Notes" }
    ]

    const tableData = [
        { col: [1,"Remedy Surgery Center (REME)", "Transcription", "Not Mentioned",  "Texas",
        "NA","Orhopedics","400 (Cases per month)", "New","Data Awaited from the US Office ","Ready For Transition"] },
        { col: [2,"Aurora Surgery Center (AURA)", "Healthcare Documentation",  "12/19/2022",  "California",
        "NA","Orthopedic","25 to 50 (cases per month)", "New","Info Awaited","Ready For Transition "] },

        // {
        //     facility: "Remedy Surgery Center (REME)", business: "Transcription", date: "Not Mentioned", state: "Texas", audit: "NA",
        //     specialty: "Orhopedics", volume: "400 (Cases per month)", status: "New", affiliation: "Data Awaited from the US Office ", notes: "Ready For Transition"
        // },
        // {
        //     facility: "Aurora Surgery Center (AURA)", business: "Healthcare Documentation", date: "12/19/2022", state: "California", audit: "NA",
        //     specialty: "Orthopedic", volume: "25 to 50 (cases per month)", status: "New", affiliation: "Info Awaited", notes: "Ready For Transition "
        // }
    ]
    return (
        <React.Fragment>
            <Card >
                <CardHeader className="bg-transparent border-bottom text-uppercase h6">Pipeline – Signed, Not Live</CardHeader>
                <CardBody>
                    {/* <div className="table-responsive">
                        <Table className="table table-sm mb-1">
                            <thead>
                                <tr>
                                    {
                                        tableColumns.map((r) => {
                                            return <th>{r.label}</th>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tableData.map((r) => {
                                        return <tr>
                                            <td>{r.facility}</td>
                                            <td>{r.business}</td>
                                            <td>{r.date}</td>
                                            <td>{r.state}</td>
                                            <td>{r.audit}</td>
                                            <td>{r.specialty}</td>
                                            <td>{r.volume}</td>
                                            <td>{r.status}</td>
                                            <td>{r.affiliation}</td>
                                            <td>{r.notes}</td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </Table>
                    </div> */}
                          <DataTable tableColumns={tableColumns} tableData={tableData} />
                </CardBody>
            </Card>
        </React.Fragment>

    )
}

export default HcPipelineSigned
