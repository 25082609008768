import React from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import {
    GridComponent,
    LegendComponent,
    TooltipComponent,
    TitleComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
]);


const HcPieChartCore = ({ data }: any) => {
    const option = {
        title: {
            left: 'center',
            textStyle: {
                color: '#999',
                fontWeight: 'normal',
                fontSize: 14,
            },
        },
        series: [
            {
                type: 'pie',
                radius: [30, 150],
                height: '100.33%',
                left: 'right',
                width: 570,
                itemStyle: {
                    borderColor: '#fff',
                    borderWidth: 1,
                },
                label: {
                    alignTo: 'edge',
                    formatter: '{name|{b}}-{value|{c}%}',
                    minMargin: 5,
                    edgeDistance: 10,
                    lineHeight: 15,

                    rich: {
                        time: {
                            fontSize: 10,
                            color: '#999',
                        },
                    },
                },
                labelLine: {
                    length: 20,
                    length2: 0,
                    maxSurfaceAngle: 80,
                },
                // labelLayout: function (params: any) {
                //     debugger
                //     const points = params.labelLinePoints;
                //     // Update the end point.
                //     points[2][0] = params.labelRect.x + params.labelRect.width;
                //     return {
                //         labelLinePoints: points,
                //     };
                // },
                data: data,
            },
        ],
    };
    return (
        <React.Fragment>
                    <ReactEChartsCore
                        style={{ height: "350px" }}
                        echarts={echarts}
                        option={option}
                        notMerge={true}
                        lazyUpdate={true}
                        theme={'theme_name'}
                        opts={{}}
                    />
        </React.Fragment>
    );
}
export default HcPieChartCore;