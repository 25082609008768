import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "./ChatDynamicColor";
import { map } from "lodash";

const BarChart = ({ dataColors, tableData, minValue }: any) => {
  const spineareaChartColors = getChartColorsArray(dataColors);

  var valueOne = map(tableData, 'valueOne')
  var label = map(tableData, 'label')
  const series = [
    {
      data: valueOne,
    },
  ]
  const options = {
    chart: {
      toolbar: {
        show: false,
        //   innerWidth: "45%",
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        columnWidth: "10%",
        endingShape: "rounded",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val: any) {
        return val
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },

    colors: spineareaChartColors,
    xaxis: {
      categories: label
    },
    yaxis: {
      min: minValue
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val;
        },
      },
    },
  }

  return (
    <ReactApexChart options={options} series={series} type="bar" height="250" />
  )
}

export default BarChart
