
import React from "react"
import { Table, Card, CardBody, CardHeader, Row, Col } from "reactstrap"
import RCMLineColumnArea from "../RCMColumnchart"
import SnapshotBarChart from "../SnapshotBarChart"
import BarChart from "../barchart"
import LineChart from "Components/Charts/linechart"
import BarChartt from "../New/Charts/Bar"


const Sample = () => {


    const codingFiles = [
        { label: "Sep-23", valueOne: 58630 },
        { label: "Aug-23", valueOne: 66031 },
        { label: "Jul-23", valueOne: 57305 },
        { label: "Jun-23", valueOne: 65027 },
        { label: "May-23", valueOne: 63339 },
        { label: "Apr-23", valueOne: 57272 },
        { label: "Mar-23", valueOne: 61355 },
        { label: "Feb-23", valueOne: 50824 },
        { label: "Jan-23", valueOne: 54092 }
    ]
    // const costPerLineINR = [
    //     { label: "Sep-23", valueOne: 82.79 },
    //     { label: "Aug-23", valueOne: 74.90 },
    //     { label: "Jul-23", valueOne: 84.14 },
    //     { label: "Jun-23", valueOne: 70.20 },
    //     { label: "May-23", valueOne: 72.12 },
    //     { label: "Apr-23", valueOne: 79.33 },
    //     { label: "Mar-23", valueOne: 71.89 },
    //     { label: "Feb-23", valueOne: 92.40 },
    //     { label: "Jan-23", valueOne: 75.35 }
    // ]
    const costPerLine$ = [
        { label: "Sep-23", valueOne: 1.01 },
        { label: "Aug-23", valueOne: 0.91 },
        { label: "Jul-23", valueOne: 1.03 },
        { label: "Jun-23", valueOne: 0.86 },
        { label: "May-23", valueOne: 0.88 },
        { label: "Apr-23", valueOne: 0.97 },
        { label: "Mar-23", valueOne: 0.88 },
        { label: "Feb-23", valueOne: 1.13 },
        { label: "Jan-23", valueOne: 0.92 }
    ]

    const totalBusDays = [
        { name: "Sep-23", value: 21 },
        { name: "Aug-23", value: 23 },
        { name: "Jul-23", value: 21 },
        { name: "Jun-23", value: 22 },
        { name: "May-23", value: 23 },
        { name: "Apr-23", value: 21 },
        { name: "Mar-23", value: 23 },
        { name: "Feb-23", value: 20 },
        { name: "Jan-23", value: 21 }
    ]

    const dailyAvg = [
        { name: "Sep-23", value: 2792 },
        { name: "Aug-23", value: 2871 },
        { name: "Jul-23", value: 2729 },
        { name: "Jun-23", value: 2956 },
        { name: "May-23", value: 2754 },
        { name: "Apr-23", value: 2727 },
        { name: "Mar-23", value: 2668 },
        { name: "Feb-23", value: 2541 },
        { name: "Jan-23", value: 2576 }
    ]

    const state = { minValue: 18 }
    const newState = { minValue: 2000 }
    return (
        <React.Fragment>
            <Row>
                <Col>
                    {/* <Card>
                    <CardHeader className="bg-transparent border-bottom text-uppercase h6">Cost Per Line (INR)</CardHeader>
                    <CardBody>
                        <LineChart dataColors='["--bs-danger","--bs-primary", "--bs-success"]' tableData={costPerLineINR} />
                    </CardBody>
                </Card> */}
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Coding Files</CardHeader>
                        <CardBody>
                            <BarChart dataColors='["--bs-primary","--bs-primary", "--bs-success"]' tableData={codingFiles} minValue={45000} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={4}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Avg Salary ($)</CardHeader>
                        <CardBody>
                            <LineChart dataColors='["--bs-danger","--bs-primary", "--bs-success"]' tableData={costPerLine$} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Avg Business Days </CardHeader>
                        <CardBody>
                            <BarChartt dataColors='["--bs-primary"]' tableData={totalBusDays} state={state} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Daily Average </CardHeader>
                        <CardBody>
                            <BarChartt dataColors='["--bs-primary"]' tableData={dailyAvg} state={newState} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}
export default Sample;