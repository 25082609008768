import React from "react";
import { Col, Row } from "reactstrap"
import HcClientSummary from "./HcClientSummary";
import HcProductionFTE from "./HcProductionFTE";
import HcSpecialtyBasedVolume from "./HcSpecialtyBasedVolume";
import HcTopClients from "./HcTopClients";
import HcQualityAudit from "./HcQualityAuditScores";
import HcClientEscalations from "./HcClientEscalations";
import HcPipelineSigned from "./HcPipelineSigned";
import HcVolumeSnapshot from "./HcVolumeSnapshot";
import HcTerminationTracker from "./HcTerminationTracker";
import HcGeographicLocations from "./HcGeographicLocations";
import DVolumeSnapshort from "Reports/HealthCare/HealthCareNewData/DVolumeSnapshort";
import DQualityAudit from "Reports/HealthCare/HealthCareNewData/DQualityAudit";
import DProductionFTE from "./HealthCareNewData/DProductionFTE";
import HealthCareDocumentation from "./HealthCareDoc";
import DSpecialtyBasedVolume from "./HealthCareNewData/DSpecialtyBasedVolume";


const HdReport = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <DVolumeSnapshort />
                    </Row>
                    <Row>
                        <DProductionFTE />
                    </Row>

                    <Row>
                        <DQualityAudit />
                    </Row>
                    <Row>
                        < DSpecialtyBasedVolume />
                    </Row>
                    {/* <Row>
                        <HcVolumeSnapshot />
                    </Row>
                    <Row>
                        <HcProductionFTE />
                    </Row>
                    <Row>
                        <Col lg={7}>
                            <HcSpecialtyBasedVolume />
                        </Col>
                    </Row> */}
                    <Row>
                        <HcClientSummary />
                    </Row>
                    <Row>
                        <HcGeographicLocations />
                    </Row>
                    {/* <Row>
                        <HcQualityAudit />
                    </Row> */}
                    <Row>
                        <HcClientEscalations />
                    </Row>
                    <Row>
                        <HcPipelineSigned />
                    </Row>
                    <Row>
                        <HcTerminationTracker />
                    </Row>
                    <Row>
                        <HealthCareDocumentation />
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default HdReport
