import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";

import classnames from "classnames";

//Import Scrollbar
import SimpleBar from "simplebar-react";

// emoji
import EmojiPicker from 'emoji-picker-react';

//Import Breadcrumb
import Breadcrumbs from "../../Components/Common/Breadcrumb";
import avatar1 from "../../assets/images/users/avatar-1.jpg";

import {
  getChats as onGetChats,
  getGroups as onGetGroups,
  getContacts as onGetContacts,
  getMessages as onGetMessages,
  addMessage as onAddMessage,
  deleteMessage as onDeleteMessage
} from "../../slices/chats/thunk"

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import Spinners from "Components/Common/Spinner";

interface Message {
  id: number;
  to_id: number;
  msg: string;
  isSameTime: boolean;
  images: string;
  time: number | string;
}

interface chatType {
  id: number,
  roomId: number,
  status: string,
  image: string,
  isImg: string,
  profile: string,
  name: string,
  description: string,
  time: string,
}

interface groupType {
  id: number,
  roomId: number,
  image: string,
  name: string,
  status: string
}
interface contactType {
  id: number,
  roomId: number,
  category: string,
  child: { id: number, roomId: number, name: string, status: string }[],
}

interface messageType {
  id: number,
  roomId: number,
  sender: string,
  message: string,
  isToday: string,
  usermessages: Message[]
}
interface chatsType {
  name: string,
  roomId: number,
  status: string,

}
const Chat = () => {

  //meta title
  document.title = "Chat | Skote - React Admin & Dashboard Template";

  const dispatch = useDispatch<any>();
  const selectProperties = createSelector(
    (state: any) => state.chats,
    (chats) => ({
      chats: chats.chats,
      groups: chats.groups,
      contacts: chats.contacts,
      messages: chats.messages,
      loading: chats.loading
    })
  );

  const {
    chats,
    groups,
    contacts,
    messages, loading
  } = useSelector(selectProperties);
  const [isLoading, setLoading] = useState(loading)

  const messageBox: any = null;
  // const Chat_Box_Username2 = "Henry Wells"
  const [currentRoomId, setCurrentRoomId] = useState<number>(1);
  // eslint-disable-next-line no-unused-vars
  const currentUser = {
    name: "Henry Wells",
    isActive: true,
  };
  const [messagesData, setMessagesData] = useState<messageType[] | undefined>();
  const [menu1, setMenu1] = useState<boolean>(false);
  const [search_Menu, setsearch_Menu] = useState<boolean>(false);
  const [settings_Menu, setsettings_Menu] = useState<boolean>(false);
  const [other_Menu, setother_Menu] = useState<boolean>(false);
  const [activeTab, setactiveTab] = useState<string | number>("1");
  const [Chat_Box_Username, setChat_Box_Username] = useState<string>("Steven Franklin");
  // eslint-disable-next-line no-unused-vars
  const [Chat_Box_User_Status, setChat_Box_User_Status] = useState<string>("online");
  const [curMessage, setcurMessage] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isdisable, setDisable] = useState<boolean>(false);
  const [emoji, setEmoji] = useState<boolean>(false);

  useEffect(() => {
    dispatch(onGetChats());
    dispatch(onGetGroups());
    dispatch(onGetContacts());
    dispatch(onGetMessages(currentRoomId));
  }, [dispatch, currentRoomId]);

  useEffect(() => {
    setMessagesData(messages);
  }, [messages]);


  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  }, [messageBox]);

  useEffect(() => {
    if (!isEmpty(messages)) scrollToBottom();
  }, [messages, scrollToBottom]);

  //Toggle Chat Box Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu);
  };

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu);
  };

  const toggleOther = () => {
    setother_Menu(!other_Menu);
  };

  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  //Use For Chat Box
  const userChatOpen = (chats: chatsType) => {
    setChat_Box_Username(chats.name);
    setCurrentRoomId(chats.roomId);
    setChat_Box_User_Status(chats.status)
    dispatch(onGetMessages(chats.roomId));
  };

  const currentTime = new Date();
  const hours = currentTime.getHours();
  const minutes = currentTime.getMinutes();
  const time: any = `${hours}: ${minutes}`
  const addMessage = () => {
    if (curMessage !== "" || selectedImage !== null) {
      const newMessage = {
        id: Math.floor(Math.random() * 100),
        to_id: 2,
        msg: curMessage,
        isSameTime: true,
        images: selectedImage,
        time: time,
      };
      dispatch(onAddMessage(newMessage));
      setcurMessage("");
      setDisable(false)
      setEmoji(false);
      setSelectedImage(null)
    }
  };

  const onKeyPress = (e: any) => {
    const { key, value } = e;
    if (key === "Enter") {
      setcurMessage(value);
      setDisable(true)
      addMessage();
    }
  };

  //serach recent user
  const searchUsers = () => {
    var input: any, filter: any, ul: any, li: any, a: any, i: any, txtValue: any;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    ul = document.getElementById("recent-list");
    li = ul.getElementsByTagName("li");
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName("a")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  };

  const [deleteMsg, setDeleteMsg] = useState<boolean>(false);
  const toggle_deleMsg = (id: number) => {
    setDeleteMsg(!deleteMsg);
    dispatch(onDeleteMessage(id))
  };
  // scroll simple bar
  const scroollRef = useRef<any>(null);
  useEffect(() => {
    if (scroollRef.current) {
      scroollRef.current.getScrollElement().scrollTop = scroollRef.current.getScrollElement().scrollHeight;
    }
  }, [messages])

  // copy msg
  const [copyMsgAlert, setCopyMsgAlert] = useState<boolean>(false);
  const copyMsg = (ele: any) => {
    var copyText = ele.closest(".conversation-list").querySelector("p").innerHTML;
    navigator.clipboard.writeText(copyText);
    setCopyMsgAlert(true)
    if (copyText) {
      setTimeout(() => {
        setCopyMsgAlert(false)
      }, 1000)

    }
  };

  // search
  const handeleSearch = () => {
    const searchInput = document.getElementById("searchMessage") as HTMLInputElement;
    const searchFilter = searchInput.value.toUpperCase();
    const searchUL = document.getElementById("users-conversation") as HTMLInputElement;
    const searchLI = searchUL.getElementsByTagName("li");

    Array.prototype.forEach.call(searchLI, (search: HTMLElement) => {
      const a = search.getElementsByTagName("p")[0] || '';
      const txtValue = a.textContent || a.innerText || '';

      if (txtValue.toUpperCase().indexOf(searchFilter) > -1) {
        search.style.display = "";
      } else {
        search.style.display = "none";
      }
    });
  };

  // emoji
  const [emojiArray, setemojiArray] = useState<string[]>([]);
  const onEmojiClick = (event: any, emojiObject: any) => {
    setemojiArray([...emojiArray, emojiObject.emoji]);
    setcurMessage(curMessage + event.emoji);
    setDisable(true)
  };

  //  img upload
  const handleImageChange = (event: any) => {
    event.preventDefault();
    let reader = new FileReader();
    let file = event.target.files[0];
    reader.onloadend = () => {
      setSelectedImage(reader.result as string);
      setDisable(true)
    };
    reader.readAsDataURL(file);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Skote" breadcrumbItem="Chat" />

          <Row>
            <Col lg={12}>
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div >
                    <div className="py-4 border-bottom">
                      <div className="d-flex">
                        <div className="align-self-center me-3">
                          <img
                            src={avatar1}
                            className="avatar-xs rounded-circle"
                            alt=""
                          />
                        </div>
                        <div className="flex-grow-1">
                          <h5 className="font-size-15 mt-0 mb-1">
                            {currentUser.name}
                          </h5>
                          <p className="text-muted mb-0">
                            <i className="mdi mdi-circle text-success align-middle me-2" />
                            Active
                          </p>
                        </div>

                        <div>
                          <Dropdown
                            isOpen={menu1}
                            toggle={() => setMenu1(!menu1)}
                            className="chat-noti-dropdown active"
                          >
                            <DropdownToggle
                              tag="a"
                              className="btn"
                            >
                              <i className="bx bx-bell bx-tada"></i>
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-end">
                              <DropdownItem href="#">Action</DropdownItem>
                              <DropdownItem href="#">Another action</DropdownItem>
                              <DropdownItem href="#">Something else</DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>

                    <div className="search-box chat-search-box py-4">
                      <div className="position-relative">
                        <Input
                          onKeyUp={searchUsers}
                          id="search-user"
                          type="text"
                          placeholder="Search..."
                          onChange={searchUsers}
                        />
                        <i className="bx bx-search-alt search-icon" />
                      </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                      <Nav pills justified>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1");
                            }}
                          >
                            <i className="bx bx-chat font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Chat</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "2",
                            })}
                            onClick={() => {
                              toggleTab("2");
                            }}
                          >
                            <i className="bx bx-group font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Groups</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "3",
                            })}
                            onClick={() => {
                              toggleTab("3");
                            }}
                          >
                            <i className="bx bx-book-content font-size-20 d-sm-none" />
                            <span className="d-none d-sm-block">Contacts</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="1">
                          <div>
                            <h5 className="font-size-14 mb-3">Recent</h5>
                            <ul className="list-unstyled chat-list position-relative" id="recent-list">
                              {
                                isLoading ? <Spinners setLoading={setLoading} /> :
                                  <SimpleBar style={{ height: "410px" }}>
                                    {chats.map((chat: chatType) => (
                                      <li
                                        key={chat.id + chat.status}
                                        className={currentRoomId === chat.roomId ? "active" : ''}
                                      >
                                        <Link
                                          to="#"
                                          onClick={() => userChatOpen(chat)}
                                        >
                                          <div className="d-flex">
                                            <div className="align-self-center me-3">
                                              <i
                                                className={
                                                  chat.status === "online"
                                                    ? "mdi mdi-circle text-success font-size-10"
                                                    : chat.status === "intermediate"
                                                      ? "mdi mdi-circle text-warning font-size-10"
                                                      : "mdi mdi-circle font-size-10"
                                                }
                                              />
                                            </div>
                                            {chat.isImg ?
                                              <div className="avatar-xs align-self-center me-3">
                                                <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                                  {chat.profile}
                                                </span>
                                              </div>
                                              :
                                              <div className="align-self-center me-3">
                                                <img
                                                  src={chat.image}
                                                  className="rounded-circle avatar-xs"
                                                  alt=""
                                                />
                                              </div>
                                            }

                                            <div className="flex-grow-1 overflow-hidden">
                                              <h5 className="text-truncate font-size-14 mb-1">
                                                {chat.name}
                                              </h5>
                                              <p className="text-truncate mb-0">
                                                {chat.description}
                                              </p>
                                            </div>
                                            <div className="font-size-11">
                                              {chat.time}
                                            </div>
                                          </div>
                                        </Link>
                                      </li>
                                    ))}
                                  </SimpleBar>
                              }
                            </ul>
                          </div>
                        </TabPane>

                        <TabPane tabId="2">
                          <h5 className="font-size-14 mb-3">Group</h5>
                          <ul className="list-unstyled chat-list">
                            <SimpleBar style={{ height: "410px" }}>
                              {groups &&
                                (groups || []).map((group: groupType) => (
                                  <li key={"test" + group.image} className={currentRoomId === group.roomId ? "active" : ""}>
                                    <Link to="#" onClick={() => { userChatOpen(group) }}>
                                      <div className="d-flex align-items-center">
                                        <div className="avatar-xs me-3">
                                          <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                            {group.image}
                                          </span>
                                        </div>

                                        <div className="flex-grow-1">
                                          <h5 className="font-size-14 mb-0">
                                            {group.name}
                                          </h5>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                ))}
                            </SimpleBar>
                          </ul>
                        </TabPane>

                        <TabPane tabId="3">
                          <h5 className="font-size-14 mb-3">Contact</h5>

                          <div>
                            <SimpleBar style={{ height: "410px" }}>
                              {contacts &&
                                (contacts || []).map((contact: contactType) => (
                                  <div
                                    key={"test_" + contact.category}
                                    className={
                                      contact.category === "A" ? "" : "mt-4"
                                    }
                                  >
                                    <div className="avatar-xs mb-3">
                                      <span className="avatar-title rounded-circle bg-primary bg-soft text-primary">
                                        {contact.category}
                                      </span>
                                    </div>

                                    <ul className="list-unstyled chat-list">
                                      {contact.child.map((array: any) => (
                                        <li key={"test" + array.id} className={currentRoomId === array.roomId ? "active" : ""}>
                                          <Link to="#" onClick={() => { userChatOpen(array) }}>
                                            <h5 className="font-size-14 mb-0">
                                              {array.name}
                                            </h5>
                                          </Link>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                            </SimpleBar>
                          </div>
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </div>
                <div className="w-100 user-chat">
                  <Card>
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md={4} xs={9}>
                          <h5 className="font-size-15 mb-1">
                            {Chat_Box_Username}
                          </h5>

                          <p className="text-muted mb-0">
                            <i
                              className={
                                Chat_Box_User_Status === "online"
                                  ? "mdi mdi-circle text-success align-middle me-2"
                                  : Chat_Box_User_Status === "intermediate"
                                    ? "mdi mdi-circle text-warning align-middle me-1"
                                    : "mdi mdi-circle align-middle me-1"
                              }
                            />
                            {Chat_Box_User_Status === "online" ? "Active now" : "Offline"}
                          </p>
                        </Col>
                        <Col md={8} xs={3}>
                          <ul className="list-inline user-chat-nav text-end mb-0">
                            <li className="list-inline-item d-none d-sm-inline-block">
                              <Dropdown
                                className="me-1"
                                isOpen={search_Menu}
                                toggle={toggleSearch}
                              >
                                <DropdownToggle className="btn nav-btn" tag="a">
                                  <i className="bx bx-search-alt-2" />
                                </DropdownToggle>
                                <DropdownMenu
                                  className="dropdown-menu-md"
                                >
                                  <Form className="p-3">
                                    <FormGroup className="m-0">
                                      <InputGroup>
                                        <Input
                                          type="text"
                                          placeholder="Search ..."
                                          id="searchMessage"
                                          aria-label="Recipient's username"
                                          onChange={handeleSearch}
                                        />
                                        <Button color="primary" type="submit">
                                          <i className="mdi mdi-magnify" />
                                        </Button>
                                      </InputGroup>
                                    </FormGroup>
                                  </Form>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                            <li className="list-inline-item d-none d-sm-inline-block">
                              <Dropdown
                                isOpen={settings_Menu}
                                toggle={toggleSettings}
                                className="me-1"
                              >
                                <DropdownToggle className="btn nav-btn" tag="a">
                                  <i className="bx bx-cog" />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem href="#">
                                    View Profile
                                  </DropdownItem>
                                  <DropdownItem href="#">
                                    Clear chat
                                  </DropdownItem>
                                  <DropdownItem href="#">Muted</DropdownItem>
                                  <DropdownItem href="#">Delete</DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                            <li className="list-inline-item">
                              <Dropdown
                                isOpen={other_Menu}
                                toggle={toggleOther}
                              >
                                <DropdownToggle className="btn nav-btn" tag="a">
                                  <i className="bx bx-dots-horizontal-rounded" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-end">
                                  <DropdownItem href="#">Action</DropdownItem>
                                  <DropdownItem href="#">
                                    Another Action
                                  </DropdownItem>
                                  <DropdownItem href="#">
                                    Something else
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <div className="chat-conversation p-3">
                        <SimpleBar ref={scroollRef} style={{ height: "470px" }}>
                          {isLoading ? <Spinners setLoading={setLoading} /> :
                            <ul className="list-unstyled" id="users-conversation">
                              {
                                messagesData && (messagesData || [])?.map((message: messageType, inx: number) => {
                                  return (
                                    <React.Fragment key={inx}>
                                      {/* <li>
                                        <div className="chat-day-title">
                                          <span className="title">{message?.isToday}</span>
                                        </div>
                                      </li> */}
                                      {
                                        message.usermessages.map((userMsg, index: number) => {
                                          return (
                                            <li
                                              key={index}
                                              className={
                                                userMsg.to_id === 1 ? "" : "right"
                                              }
                                            >
                                              <div className="conversation-list">
                                                <UncontrolledDropdown>
                                                  <DropdownToggle
                                                    href="#!"
                                                    tag="a" className="dropdown-toggle"
                                                  >
                                                    <i className="bx bx-dots-vertical-rounded" />
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    <DropdownItem onClick={(e) => copyMsg(e.target)} href="#">
                                                      Copy
                                                    </DropdownItem>
                                                    <DropdownItem href="#">
                                                      Save
                                                    </DropdownItem>
                                                    <DropdownItem href="#">
                                                      Forward
                                                    </DropdownItem>
                                                    <DropdownItem onClick={(e) => toggle_deleMsg(userMsg.id)} href="#">
                                                      Delete
                                                    </DropdownItem>

                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                                <div className="ctext-wrap">
                                                  <div className="conversation-name">
                                                    {userMsg.to_id === 1 ? message.sender : "You"}
                                                  </div>
                                                  <p>{userMsg.msg}</p>
                                                  {userMsg.images && <img src={userMsg.images} alt="" width="150px" />}
                                                  {userMsg.time !== 0 && <p className="chat-time mb-0"><i className="bx bx-time-five align-middle me-1"></i>{userMsg.time}</p>}
                                                </div>
                                              </div>
                                            </li>
                                          )
                                        })
                                      }
                                    </React.Fragment>)

                                })
                              }
                            </ul>
                          }
                        </SimpleBar>
                      </div>
                      {
                        selectedImage &&
                        <div className="replymessage-block mb-0 d-flex align-items-start">
                          <div className="flex-grow-1">
                            <img src={selectedImage} alt="select img" style={{ width: "150px", height: "auto" }} />
                          </div>
                          <div className="flex-shrink-0">
                            <button type="button" id="close_toggle" className="btn btn-sm btn-link mt-n2 me-n3 fs-18" onClick={() => setSelectedImage(null)}>
                              <i className="bx bx-x align-middle"></i>
                            </button>
                          </div>
                        </div>
                      }
                      {copyMsgAlert && <UncontrolledAlert color='warning' role="alert">Message copied</UncontrolledAlert>}
                      {emoji && <EmojiPicker onEmojiClick={onEmojiClick} width={250} height={382} />}
                      <div className="p-3 chat-input-section">
                        <Row>
                          <Col>
                            <div className="position-relative">
                              <Input
                                type="text"
                                value={curMessage}
                                onKeyPress={onKeyPress}
                                onChange={e => { setcurMessage(e.target.value); setDisable(true) }}
                                className="chat-input"
                                placeholder="Enter Message..."
                              />
                              <div className="chat-input-links">
                                <ul className="list-inline mb-0">
                                  <li className="list-inline-item" onClick={() => setEmoji(!emoji)}>
                                    <Link to="#">
                                      <i
                                        className="mdi mdi-emoticon-happy-outline me-1"
                                        id="Emojitooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="Emojitooltip"
                                      >
                                        Emojis
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                  <li className="list-inline-item">
                                    <label htmlFor="imageInput" style={{ color: "#556ee6", fontSize: 16 }}>
                                      <i className="mdi mdi-file-image-outline me-1" id="Imagetooltip" />
                                      <UncontrolledTooltip placement="top" target="Imagetooltip">
                                        Images
                                      </UncontrolledTooltip>
                                    </label>
                                    <input type="file" id="imageInput" className="d-none" onChange={handleImageChange} />
                                  </li>
                                  <li className="list-inline-item">
                                    <Link to="#">
                                      <i className="mdi mdi-file-document-outline" id="Filetooltip" />
                                      <UncontrolledTooltip placement="top" target="Filetooltip">
                                        Add Files
                                      </UncontrolledTooltip>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              type="button"
                              color="primary"
                              disabled={!isdisable}
                              onClick={() => addMessage()}
                              className="btn btn-primary btn-rounded chat-send w-md "
                            >
                              <span className="d-none d-sm-inline-block me-2">
                                Send
                              </span>
                              <i className="mdi mdi-send" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};



export default Chat;
