import React from "react"
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import BarChart from "./Charts/Bar"
import Line from "./Charts/LineWithData"

// import { map, find, pick, keys } from "lodash";
// import MultipleBarChart from "./Charts/LineWithData";

const QualityAuditScores = () => {

    const AccuracytableData = [
        { name: "Oct-23", value: 99.20 },
        { name: "Sep-23", value: 99.31 },
        { name: "Aug -23", value: 99.30 },
        { name: "Jul-23", value: 99.40 },
        { name: "Jun-23", value: 99.42 },
        { name: "May -23", value: 99.22 },
        { name: "Apr -23", value: 99.17 },
    ]

    const ErrorstableData = [
        { name: "Oct-23", value: 368 },
        { name: "Sep-23", value: 276 },
        { name: "Aug-23", value: 306 },
        { name: "Jul-23", value: 286 },
        { name: "Jun-23", value: 332 },
        { name: "May-23", value: 437 },
        { name: "Apr-23", value: 404 },
    ]
    const PossiblePointsData = [
        { name: "Oct-23", value: 46235 },
        { name: "Sep-23", value: 39776 },
        { name: "Aug -23", value: 43918 },
        { name: "Jul-23", value: 47445 },
        { name: "Jun-23", value: 55332 },
        { name: "May -23", value: 55680 },
        { name: "Apr -23", value: 48635 },
    ]
    const ReportAduitData = [
        { name: "Oct-23", value: 5184 },
        { name: "Sep-23", value: 4481 },
        { name: "Aug -23", value: 5033 },
        { name: "Jul-23", value: 5220 },
        { name: "Jun-23", value: 6383 },
        { name: "May -23", value: 7004 },
        { name: "Apr -23", value: 5558 },

    ]
    const AduitSample = [
        { name: "Oct-23", value: 8.20 },
        { name: "Sep-23", value: 7.60 },
        { name: "Aug-23", value: 7.60 },
        { name: "Jul-23", value: 9.10 },
        { name: "Jun-23", value: 9.80 },
        { name: "May-23", value: 11.10 },
        { name: "Apr-23", value: 9.70 },
    ]

    const state = {
        minValue: 99
    }
    const EmptyState = {
        minValue: 0
    }

    const lineValue = {
        minValue: 5
    }



    // const tableColumns = ["Oct-23", "Sep-23", "Aug-23", "Jul-23", "Jun-23", "May-23", "Apr-23"]
    // const tabledata = [
    //     { label: "Total reports audited", valueOne: 5184, valueTwo: 4481, valueThree: 5033, valueFour: 5220, valueFive: 6383, valueSix: 7004, valueSeven: 5558 },
    //     { label: "Total possible points ", valueOne: 46235, valueTwo: 39776, valueThree: 43918, valueFour: 47445, valueFive: 55332, valueSix: 55680, valueSeven: 48635 },
    //     { label: "Total errors", valueOne: 368, valueTwo: 276, valueThree: 306, valueFour: 286, valueFive: 332, valueSix: 437, valueSeven: 404 },
    // ]




    // const state = {
    //     horizontal: false,
    //     height: "350",
    //     title: "Quality Audit Scores",
    //     minValue: 100
    // }


    // var model = {
    //     valueOne: null,
    //     valueTwo: null,
    //     valueThree: null,
    //     valueFour: null,
    //     valueFive: null,
    //     valueSix: null,
    //     valueSeven: null
    // };
    // var label = map(tabledata, 'label')
    // var data1 = find(tabledata, function (o) { return o.label === "Total reports audited"; });
    // var data2 = find(tabledata, function (o) { return o.label === "Total possible points "; });
    // var data3 = find(tabledata, function (o) { return o.label === "Total errors"; });

    // var result1 = pick(data1, keys(model));
    // var result2 = pick(data2, keys(model));
    // var result3 = pick(data3, keys(model));
    // var totalReportsAudited = Object.values(result1)
    // var totalPossiblePoints = Object.values(result2)
    // var totalErrors = Object.values(result3)


    // const series = [
    //     {
    //         name: label[0],
    //         data: totalReportsAudited,
    //     },
    //     {
    //         name: label[1],
    //         data: totalPossiblePoints,
    //     },
    //     {
    //         name: label[2],
    //         data: totalErrors,
    //     },

    // ]

    return (
        <React.Fragment>
            <Row>
                <Row>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h4 App">Quality Audit Scores</CardHeader>
                    </Card>
                    <Col lg={4}>
                        <Card>
                            <CardBody>
                                <CardHeader className="bg-transparent border-bottom text-uppercase h6">Total Charts Audited</CardHeader>
                                <BarChart dataColors='["--bs-primary","--bs-success", "--bs-danger", "--bs-warning"]' tableData={ReportAduitData} state={EmptyState} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardBody>
                                <CardHeader className="bg-transparent border-bottom text-uppercase h6">Total Possible Points</CardHeader>
                                <BarChart dataColors='["--bs-primary","--bs-success", "--bs-danger", "--bs-warning"]' tableData={PossiblePointsData} state={EmptyState} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card>
                            <CardBody>
                                <CardHeader className="bg-transparent border-bottom text-uppercase h6">Total Errors</CardHeader>
                                <BarChart dataColors='["--bs-primary","--bs-success", "--bs-danger", "--bs-warning"]' tableData={ErrorstableData} state={EmptyState} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                {/* <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <MultipleBarChart dataColors='["--bs-danger","--bs-primary", "--bs-success"]'
                                    tableColumns={tableColumns} dataseries={series} state={state} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row> */}
                <Row>
                    <Col lg={4}>
                        <Card>
                            <CardBody>
                                <CardHeader className="bg-transparent border-bottom text-uppercase h6">Total Accuracy</CardHeader>
                                <BarChart dataColors='["--bs-primary","--bs-success", "--bs-danger", "--bs-warning","--bs-info"]' tableData={AccuracytableData} state={state} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Card>
                            <CardBody>
                                <CardHeader className="bg-transparent border-bottom text-uppercase h6">Audit Sampling</CardHeader>
                                {/* <BarChart dataColors='["--bs-primary","--bs-success", "--bs-danger", "--bs-warning","--bs-info"]' tableData={AduitSample} state={EmptyState} /> */}
                                <Line dataColors='["--bs-success"]' tableData={AduitSample} state={lineValue} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Row>
        </React.Fragment>

    )
}
export default QualityAuditScores;