import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "../getChartColorsArray";

const HcBarChart = ({ dataColors, tableColumns, dataseries, state }: any) => {

    const spineareaChartColors = getChartColorsArray(dataColors);
    const series = dataseries
    const options = {
        chart: {
            toolbar: {
                show: false,
                innerWidth: "45%",
            },
        },
        plotOptions: {
            bar: {
                horizontal: state.horizontal,
                endingShape: "rounded",
                dataLabels: {
                    position: "top", // top, center, bottom
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: any) {
                return state.symbol === "%" ? val + state.symbol : state.symbol === "$" || state.symbol === "$" ?
                    state.symbol + val : val
            },
        },
        stroke: {
            show: true,
            width: 1,
            colors: ["transparent"],
        },

        colors: spineareaChartColors,
        xaxis: {
            categories: tableColumns
        },
        yaxis: {
            min: state.minValue,
            title: {
                text: state.title,
            },

        }, title: {
            // text: "Points",
        },
        grid: {
            borderColor: "#f1f1f1",
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val: any) {
                    return val;
                },
            },
        },
    }
    return (
        <React.Fragment>
            <ReactApexChart options={options} series={series} type="bar" height={state.height} />
        </React.Fragment >

    )
}
export default HcBarChart;