
import LineChart from "Components/Charts/linechart"
import React from "react"
import { Table, Card, CardBody, CardHeader, Row, Col } from "reactstrap"
import BarChart from "Reports/Coding/barchart"
import BarChartt from "Reports/Coding/New/Charts/Bar"
// import RCMLineColumnArea from "../RCMColumnchart"
// import SnapshotBarChart from "../SnapshotBarChart"
// import BarChart from "../barchart"
// import LineChart from "Components/Charts/linechart"
// import BarChartt from "../New/Charts/Bar"


const ManPower = () => {


    const COGdata = [
        { label: "Nov-22", valueOne: 44 },
        { label: "Dec-22", valueOne: 69 },
        { label: "Jan-23", valueOne: 97 },
        { label: "Feb-23", valueOne: 143 },
        { label: "Mar-23", valueOne: 215 },
        { label: "Apr-23", valueOne: 260 },
        { label: "May-23", valueOne: 280 },
        { label: "Jun-23", valueOne: 298 },
        { label: "Jul-23", valueOne: 310 },
        { label: "Aug-23", valueOne: 308 },
        { label: "Sep-23", valueOne: 295 },
        { label: "Oct-23", valueOne: 286 },
    ]
    const LeaderQAdata = [
        { name: "Nov-22", value: 5 },
        { name: "Dec-22", value: 7 },
        { name: "Jan-23", value: 13 },
        { name: "Feb-23", value: 21 },
        { name: "Mar-23", value: 32 },
        { name: "Apr-23", value: 30 },
        { name: "May-23", value: 36 },
        { name: "Jun-23", value: 39 },
        { name: "Jul-23", value: 39 },
        { name: "Aug-23", value: 34 },
        { name: "Sep-23", value: 34 },
        { name: "Oct-23", value: 37 },
    ]

    const state = {
        minValue: 0
    }
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h4 App">ManPower(Month-on-Month)</CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">COG</CardHeader>
                        <CardBody>
                            <BarChart dataColors='["--bs-primary"]' tableData={COGdata} minValue={30} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">LeaderShip/QA/Support</CardHeader>
                        <CardBody>
                            <BarChartt dataColors='["--bs-primary"]' tableData={LeaderQAdata} state={state} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}
export default ManPower;


// import React from "react"
// import { Table, Card, CardBody, CardHeader, Row, Col } from "reactstrap"
// import RCMLineColumnArea from "../RCMColumnchart"
// import SnapshotBarChart from "../SnapshotBarChart"
// import BarChart from "../barchart"
// import LineChart from "Components/Charts/linechart"
// import Apaexlinecolumn from "Components/Charts/apaexlinecolumn"
// import getChartColorsArray from "Components/Common/ChartDynamicColor"


// const AverageProductionFTE = () => {
//     const apaexlineColumnColors = getChartColorsArray('["--bs-danger","--bs-primary", "--bs-success"]');
//     const months = [
//         "Nov-22",
//         "Dec-22",
//         "Jan-23",
//         "Feb-23",
//         "Mar-23",
//         "Apr-23",
//         "May-23",
//         "Jun-23",
//         "Jul-23",
//         "Aug-23",
//         "Sep-23",
//         "Oct-23"
//     ]
//     const numberOfEmpSeries = [
//         {
//             name: "Medical Coder",
//             data: [65, 69, 69, 72, 71, 72, 73],
//         },
//         {
//             name: "Proof Reader/Quality Controller",
//             data: [16, 17, 17, 17, 16, 17, 13],
//         },
//         {
//             name: "Account Expert ",
//             data: [26, 33, 30, 29, 27, 29, 30],
//         },
//     ];
//     const numberOfEmpOptions = {
//         chart: {
//             toolbar: {
//                 show: false,
//             },
//         },
//         plotOptions: {
//             bar: {
//                 horizontal: false,
//                 columnWidth: "75%",
//                 endingShape: "rounded",
//             },
//         },
//         dataLabels: {
//             enabled: true,
//             formatter: function (val: any) {
//                 return val
//             },
//         },
//         stroke: {
//             show: true,
//             width: 2,
//             colors: ["transparent"],
//         },

//         colors: apaexlineColumnColors,
//         xaxis: {
//             categories: months
//         },
//         yaxis: {
//             title: {
//                 text: "No. of Employees ",
//             },
//         },
//         grid: {
//             borderColor: "#f1f1f1",
//         },
//         fill: {
//             opacity: 1,
//         },
//         tooltip: {
//             y: {
//                 formatter: function (val: any) {
//                     return val + "Members";
//                 },
//             },
//         },
//     };

//     const totalLinesProcessedSeries = [
//         {
//             name: "Medical Coder",
//             data: [49652, 45961, 50191, 44264, 51109, 48101, 44603],
//         },
//         {
//             name: "Proof Reader/Quality Controller",
//             data: [49652, 45961, 50191, 44264, 51109, 48101, 44603],
//         },
//         {
//             name: "Account Expert ",
//             data: [13443, 12669, 15840, 13041, 13918, 15238, 16752],
//         },
//     ];
//     const totalLinesProcessedOptions = {
//         chart: {
//             toolbar: {
//                 show: false,
//             },
//         },
//         plotOptions: {
//             bar: {
//                 horizontal: true,
//                 columnWidth: "45%",
//                 endingShape: "rounded",
//             },
//         },
//         dataLabels: {
//             enabled: true,
//             formatter: function (val: any) {
//                 return val
//             },
//         },
//         stroke: {
//             show: true,
//             width: 2,
//             colors: ["transparent"],
//         },

//         colors: apaexlineColumnColors,
//         xaxis: {
//             categories: months,
//             title: {
//                 text: "Total Lines Processed",
//             },
//         },

//         grid: {
//             borderColor: "#f1f1f1",
//         },
//         fill: {
//             opacity: 1,
//         },
//         tooltip: {
//             y: {
//                 formatter: function (val: any) {
//                     return val + " Lines Processed";
//                 },
//             },
//         },
//     };
//     const avgFTELinesSeries = [
//         {
//             name: "Medical Coder",
//             data: [764, 666, 727, 615, 720, 668, 578],
//         },
//         {
//             name: "Proof Reader/Quality Controller",
//             data: [3103, 2704, 2952, 2604, 3194, 2829, 3246],
//         },
//         {
//             name: "Account Expert ",
//             data: [640, 384, 528, 450, 515, 525, 502],
//         },
//     ];
//     const avgFTELinesOptions = {
//         chart: {
//             toolbar: {
//                 show: false,
//             },
//         },
//         plotOptions: {
//             bar: {
//                 horizontal: false,
//                 columnWidth: "75%",
//                 endingShape: "rounded",
//             },
//             dataLabels: {
//                 position: "top", // top, center, bottom
//             },
//         },
//         dataLabels: {
//             enabled: true,
//             formatter: function (val: any) {
//                 return val
//             },
//         },
//         stroke: {
//             show: true,
//             width: 2,
//             colors: ["transparent"],
//         },

//         colors: apaexlineColumnColors,
//         xaxis: {
//             categories: months,
//         },
//         yaxis: {
//             title: {
//                 text: "Average FTE ",
//             },
//         },

//         grid: {
//             borderColor: "#f1f1f1",
//         },
//         fill: {
//             opacity: 1,
//         },
//         tooltip: {
//             y: {
//                 formatter: function (val: any) {
//                     return val + " Average FTE Lines";
//                 },
//             },
//         },
//     };

//     return (
//         <React.Fragment>
//             <Row>
//                 <Card>
//                     <CardHeader className="bg-transparent border-bottom text-uppercase h4 App">Average Production by FTE </CardHeader>
//                 </Card>
//                 <Col lg={6}>
//                     <Card>
//                         <CardHeader className="bg-transparent border-bottom text-uppercase h6">No. of Employees</CardHeader>
//                         <CardBody>
//                             <Apaexlinecolumn series={numberOfEmpSeries} options={numberOfEmpOptions} />
//                         </CardBody>
//                     </Card>
//                 </Col>
//                 <Col lg={6}>
//                     <Card>
//                         <CardHeader className="bg-transparent border-bottom text-uppercase h6">Avg Total Processed </CardHeader>
//                         <CardBody>
//                             <Apaexlinecolumn series={totalLinesProcessedSeries} options={totalLinesProcessedOptions} />
//                         </CardBody>
//                     </Card>
//                 </Col>
//             </Row>
//             <Row>
//                 <Col>
//                     <Card>
//                         <CardHeader className="bg-transparent border-bottom text-uppercase h6">Average FTE Chart</CardHeader>
//                         <CardBody>
//                             <Apaexlinecolumn series={avgFTELinesSeries} options={avgFTELinesOptions} />
//                         </CardBody>
//                     </Card>
//                 </Col>
//             </Row>
//         </React.Fragment>
//     )
// }
// export default AverageProductionFTE;