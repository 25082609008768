import DataTable from "Components/DataTable";
import React from "react"
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";

const HcClientSummary = () => {
    const tableColumns = [
        { label: "Affiliation" }, { label: "Full Name" }, { label: "Volume/Number of clients per affiliation" },
    ]

    const affiliationsOne = [
        { col: ["Independent", "Independent", 165] },
        { col: ["Independent", "HCA Healthcare", 61] },
        { col: ["NSNREVALL", "NSN Revenue - All", 25] },
        { col: ["USPI", "United Surgical Partners International", 25] },
        { col: ["AMSURG", "AmSurg Centers", 23] },
        { col: ["SCA", "Surgical Care Affiliates", 12] },
        { col: ["SCP", "Surgery Partners", 9] },
        { col: ["SUTR", "Sutter Health", 9] },
        { col: ["REGENTRCM", "Regent Revenue Cycle Management", 6] },
        { col: ["INOVA", "INOVA centers", 5] },
        { col: ["MONTEREY", "Monterey Peninsula", 4] },
        { col: ["AVP", "American Vision Partners", 3] }
        // { affiliation: "Independent", name: "Independent", volume: 165 },
        // { affiliation: "Independent", name: "HCA Healthcare", volume: 61 },
        // { affiliation: "NSNREVALL", name: "NSN Revenue - All", volume: 25 },
        // { affiliation: "USPI", name: "United Surgical Partners International", volume: 25 },
        // { affiliation: "AMSURG", name: "AmSurg Centers", volume: 23 },
        // { affiliation: "SCA", name: "Surgical Care Affiliates", volume: 12 },
        // { affiliation: "SCP", name: "Surgery Partners", volume: 9 },
        // { affiliation: "SUTR", name: "Sutter Health", volume: 9 },
        // { affiliation: "REGENTRCM", name: "Regent Revenue Cycle Management", volume: 6 },
        // { affiliation: "INOVA", name: "INOVA centers", volume: 5 },
        // { affiliation: "MONTEREY", name: "Monterey Peninsula", volume: 4 },
        // { affiliation: "AVP", name: "American Vision Partners", volume: 3 },
    ]
    const affiliationsTwo = [
        { col: ["NTRA", "Neuterra", 3] },
        { col: ["SURGCENTERDEV", "SurgCenter Dev", 3] },
        { col: ["NSNREVALL", "NSN Revenue - All", 25] },
        { col: ["WILLS", "Wills Surgery Center", 3] },
        { col: ["ASCOA", "Ascoa Centers ", 2] },
        { col: ["CSC", "Constitution Surgery Centers", 2] },
        { col: ["KOCW", "Kansas Orthopedic Center", 2] },
        { col: ["SYMB", "Symbion", 2] },
        { col: ["CMPS", "Compass", 1] },
        { col: ["NCP", "National Cardiovascular Partners, LP", 1] },
        { col: ["NSH", "National Surgical Hospitals", 1] },
        { col: ["ONCR", "Oncure", 1] },
        { col: ["ROTH", "Rothman Clients ", 1] }

        // { affiliation: "NTRA", name: "Neuterra", volume: 3 },
        // { affiliation: "SURGCENTERDEV", name: "SurgCenter Dev", volume: 3 },
        // { affiliation: "NSNREVALL", name: "NSN Revenue - All", volume: 25 },
        // { affiliation: "WILLS", name: "Wills Surgery Center", volume: 3 },
        // { affiliation: "ASCOA", name: "Ascoa Centers ", volume: 2 },
        // { affiliation: "CSC", name: "Constitution Surgery Centers", volume: 2 },
        // { affiliation: "KOCW", name: "Kansas Orthopedic Center", volume: 2 },
        // { affiliation: "SYMB", name: "Symbion", volume: 2 },
        // { affiliation: "CMPS", name: "Compass", volume: 1 },
        // { affiliation: "NCP", name: "National Cardiovascular Partners, LP", volume: 1 },
        // { affiliation: "NSH", name: "National Surgical Hospitals", volume: 1 },
        // { affiliation: "ONCR", name: "Oncure", volume: 1 },
        // { affiliation: "ROTH", name: "Rothman Clients ", volume: 1 },
    ]
    return (
        <React.Fragment>
            <Row>
                <Col lg={6}>
                    <Card >
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Client Summary – Affiliations -1</CardHeader>
                        <CardBody>

                            {/* <div className="table-responsive">
                                <Table className="table table-sm mb-1">
                                    <thead>
                                        <tr>
                                            {
                                                tableColumns.map((r) => {
                                                    return <th>{r.label}</th>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            affiliationsOne.map((r) => {
                                                return <tr>
                                                    <td>{r.affiliation}</td>
                                                    <td>{r.name}</td>
                                                    <td>{r.volume}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div> */}
                            <DataTable tableColumns={tableColumns} tableData={affiliationsOne} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card >
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Client Summary – Affiliations -2 </CardHeader>
                        <CardBody>
                            {/* <div className="table-responsive">
                                <Table className="table table-sm mb-1">
                                    <thead>
                                        <tr>
                                            {
                                                tableColumns.map((r) => {
                                                    return <th>{r.label}</th>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            affiliationsTwo.map((r) => {
                                                return <tr>
                                                    <td>{r.affiliation}</td>
                                                    <td>{r.name}</td>
                                                    <td>{r.volume}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div> */}
                                <DataTable tableColumns={tableColumns} tableData={affiliationsTwo} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>

    )
}

export default HcClientSummary
