import React from "react";
import { Row } from "reactstrap"
import OrganizationalChart from "Reports/OrgCharts/OrganizationalChart";
import StaffingUpdates from "./StaffingUpdates";
import TechnologyTools from "./TechnologyTools";
const Others = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <StaffingUpdates/>
                    </Row>
                    <Row>
                        <TechnologyTools/>
                    </Row>
                    <Row>
                    <OrganizationalChart/>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Others
