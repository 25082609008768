import React from "react";
import ReactEcharts from "echarts-for-react";
import getChartColorsArray from "./ChatDynamicColor";

const Pie = ({ dataColors, option }: any) => {
  const PieEChartColors = getChartColorsArray(dataColors);
  const options = {
    toolbox: {
      show: false,
    },
    tooltip: {
      trigger: "item",
      formatter: "{a} <br/>{b} : {c}",
    },
    color: PieEChartColors,
    series: [
      {
        name: "Total",
        type: "pie",
        radius: "65%",
        center: ["50%", "50%"],
        data: option,
        itemStyle: {
          emphasis: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
      },
    ],
  };
  return (
    <React.Fragment>
      <ReactEcharts style={{ height: "350px" }} option={options} />
    </React.Fragment>
  );
};
export default Pie;
