import DataTable from "Components/DataTable"
import React from "react"
import { Table, Card, CardBody, CardHeader } from "reactstrap"

const Pipeline = () => {
    const tableColumns = [{ label: "Facility" }, { label: "Line of Business" }, { label: "Expected Start Date" },
    { label: "ST" }, { label: "Specialty" }, { label: "Mo Vol" }, { label: "New/ Existing" },
    { label: "Annualized Monthly Revenue" }, { label: "Fee" }, { label: "Who had business prior?" },
    { label: "Referral Source Type" }, { label: "Referral Source Name" }, { label: "Affiliations" }, { label: "Notes" }]
    const tableData = [
        { col: ["Ambulatory Surgery & Laser Center of Cape Cod", "MedTek - ASC", "Soon", "MA", "Surgery", 400, "New", "", "", "", "", "", "", ""] },
        { col: ["Loma Linda Surgical Center", "MedTek – ASC", "Soon", "CA", "Surgery", 700, "New", "", "", "", "", "", "", ""] }
    ]

    return (
        <React.Fragment>
            <Card >
                <CardHeader className="bg-transparent border-bottom text-uppercase h6">Pipeline – Signed, Not  Live </CardHeader>
                <CardBody>
                    {/* <Table className="table table-sm mb-1">
                        <thead>
                            <tr>
                                {
                                    tableColumns.map((r) => {
                                        return <th>{r}</th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                tableData.map((r) => {
                                    return <tr>
                                        {r.col.map((c) => {
                                            return <td>{c}</td>
                                        })}
                                    </tr>
                                })
                            }
                        </tbody>
                    </Table> */}
                    <DataTable tableColumns={tableColumns} tableData={tableData} />
                </CardBody>
            </Card>
        </React.Fragment>

    )
}
export default Pipeline;