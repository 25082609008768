//import images
import airbnb from "../../assets/images/companies/airbnb.svg";
import mailchimp from "../../assets/images/companies/mailchimp.svg";
import reddit from "../../assets/images/companies/reddit.svg";
import amzon from "../../assets/images/companies/amazon.svg";
import adobPhotoshop from "../../assets/images/companies/adobe-photoshop.svg";
import line from "../../assets/images/companies/line.svg";
import wechat from "../../assets/images/companies/wechat.svg";
import sass from "../../assets/images/companies/sass.svg";
import adobe from "../../assets/images/companies/adobe.svg";
import flutter from "../../assets/images/companies/flutter.svg";
import spotify from "../../assets/images/companies/spotify.svg";
import avatar5 from '../../assets/images/users/avatar-5.jpg';


const jobVacancy = [
    {
        id: 1,
        img: airbnb,
        title: "Project Manager",
        country: " California",
        vacancy: 8
    },
    {
        id: 2,
        img: mailchimp,
        title: "Marketing Director",
        country: "Danemark",
        vacancy: 12
    },
    {
        id: 3,
        img: reddit,
        title: "Product Designer",
        country: "France",
        vacancy: 25
    },
    {
        id: 4,
        img: amzon,
        title: "Magento Developer",
        country: "Hong-Kong",
        vacancy: 8
    },
    {
        id: 5,
        img: adobPhotoshop,
        title: "Product Sales Specialist",
        country: " Louisiana",
        vacancy: 1
    },
    {
        id: 6,
        img: line,
        title: "Business Associate",
        country: " Phoenix",
        vacancy: 15
    },
];

const statisticsApplications = [
    // year
    {
        id: 1,
        data: {
            companay: [30, 48, 28, 74, 39, 87, 54, 36, 50, 87, 84],
            newjobs: [20, 50, 42, 10, 24, 28, 60, 35, 47, 64, 78],
            totaljobs: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
            jobview: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
        }
    },
    // month
    {
        id: 2,
        data: {
            companay: [25, 95, 87, 14, 56, 12, 14, 56, 35, 14, 25],
            newjobs: [85, 87, 56, 45, 14, 87, 56, 98, 19, 51, 78, 49],
            totaljobs: [85, 75, 95, 48, 59, 68, 15, 35, 26, 45, 59, 57],
            jobview: [78, 89, 84, 98, 67, 59, 48, 42, 35, 29, 18, 59],
        }
    },
    // week
    {
        id: 3,
        data: {
            companay: [30, 48, 28, 74, 39, 87, 54, 56, 35, 14, 30],
            newjobs: [52, 43, 69, 75, 49, 28, 56, 19, 51, 60, 49],
            totaljobs: [57, 49, 68, 87, 71, 29, 49, 26, 50, 59, 57],
            jobview: [78, 84, 95, 76, 68, 57, 48, 35, 29, 20, 59],
        }
    }
];

const activityFeedData = [
    {
        type: "application",
        name: "Charles Brown",
        action: "applied for the job",
        jobTitle: "Sr.frontend Developer",
        timestamp: "3 min ago",
        img: avatar5,
    },
    {
        type: "subscription",
        message: "Your subscription expires today",
        actionLink: "#",
        timestamp: "53 min ago"
    },
    {
        type: "accountCreation",
        name: "Jennifer Alexandar",
        action: "created a new account as a",
        accountType: "Freelance",
        timestamp: "1 hrs ago"
    },
    {
        type: "application",
        name: "Mark Ellison",
        action: "applied for the job",
        jobTitle: "Project Manager",
        timestamp: "3 hrs ago",
        img: avatar5,
    },
    {
        type: "accountCreation",
        name: "Acolin Zelton",
        action: "created a new account as a",
        accountType: "Freelance",
        timestamp: "1 hrs ago"
    }
]
const recentAddedJobsData = [
    {
        logo: wechat,
        jobTitle: "Marketing Director",
        company: "Themesbrand, USA",
        postedTime: "53 sec ago"
    },
    {
        logo: sass,
        jobTitle: "Frontend Developer",
        company: "Themesbrand, Hong-Kong",
        postedTime: "47 min ago"
    },
    {
        logo: adobe,
        jobTitle: "React Developer",
        company: "Creative Agency, Danemark",
        postedTime: "1 hrs ago"
    },
    {
        logo: airbnb,
        jobTitle: "NodeJs Developer",
        company: "Skote Themes, Louisiana",
        postedTime: "2 hrs ago"
    },
    {
        logo: flutter,
        jobTitle: "Digital Marketing",
        company: "Web Technology pvt.Ltd, Danemark",
        postedTime: "8 hrs ago"
    },
    {
        logo: mailchimp,
        jobTitle: "Marketing Director",
        company: "Skote Technology, Dominica",
        postedTime: "1 days ago"
    },
    {
        logo: spotify,
        jobTitle: "Business Associate",
        company: "Themesbrand, Russia",
        postedTime: "2 days ago"
    },
    {
        logo: reddit,
        jobTitle: "Backend Developer",
        company: "Adobe Agency, Malaysia",
        postedTime: "3 days ago"
    }
]


export { jobVacancy, statisticsApplications, activityFeedData, recentAddedJobsData };