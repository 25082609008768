
import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "Reports/Coding/ChatDynamicColor";
import { map } from "lodash";


const BarChartt = ({ dataColors, tableData, dataSeries, state }: any) => {
    const spineareaChartColors = getChartColorsArray(dataColors);
    var name = map(tableData, 'name')
    var value = map(tableData, 'value')
    const series = [
        {

            data: value

        },

    ]
    const options: any = {
        chart: {
            toolbar: {

                show: false,
            },
        },
        // series: value,
        plotOptions: {
            bar: {
                dataLabels: {
                    position: "top", // top, center, bottom
                },
            },
        },
        dataLabels: {
            enabled: true,
            // formatter: function (val: any) {
            //     return val + "%"
            // },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#000000"],
            },
        },

        colors: spineareaChartColors,
        grid: {
            borderColor: "#f1f1f1",
        },
        xaxis: {
            categories: name,
            position: "center",
            labels: {
                offsetY: -1,
            },
            axisBorder: {
                show: true,
            },
        },
        yaxis: {
            min: state.minValue,
            title: {
                // text:,
            },
            // formatter: function (val: any) {
            //     return val + ;
            // },
        },
        fill: {
            gradient: {
                type: "horizontal",
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100],
            },
        },
    }
    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={series}
                type="bar"
                height={250}
                className="apex-charts"
            />
        </React.Fragment>

    )
}
export default BarChartt;