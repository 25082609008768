// import React from "react"
// import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
// import { map, find, pick, keys } from "lodash";
// import MultipleBarChart from "./Charts/LineWithData";

// const SpecialityVolume = () => {

//     const tableColumns = ["Oct-23", "Sep-23", "Aug-23", "Jul-23", "Jun-23", "May-23", "Apr-23"]
//     const tabledata = [
//         { label: "Musculoskeletal", valueOne: "25.95%", valueTwo: "25.67%", valueThree: "26.79%", valueFour: "26.54%", valueFive: "26.75%", valueSix: "27%", valueSeven: "27%" },
//         { label: "GI", valueOne: "26.37%", valueTwo: "24.10%", valueThree: "23.97%", valueFour: "23.71%", valueFive: "23.49%", valueSix: "23%", valueSeven: "23%" },
//         { label: "Cataract", valueOne: "12.88%", valueTwo: "14.10%", valueThree: "13.18%", valueFour: "13.03%	", valueFive: "13.04%", valueSix: "13%", valueSeven: "13%" },
//         { label: "Spinal Injections", valueOne: "9.02%", valueTwo: "8.50%", valueThree: "7.73%	", valueFour: "7.69%", valueFive: "7.69%", valueSix: "8%", valueSeven: "8%" },
//         { label: "Digestive", valueOne: "4.34%", valueTwo: "7.03%", valueThree: "6.14%", valueFour: "5.93%", valueFive: "5.93%", valueSix: "6%", valueSeven: "6%" },
//         { label: "Eyes", valueOne: "4.02%", valueTwo: "4.20%", valueThree: "4.78%", valueFour: "4.60%", valueFive: "4.60%", valueSix: "5%", valueSeven: "5%" },
//     ]


//     const state = {
//         horizontal: false,
//         height: "350",
//         // title: "Specialty Based Volume -Modular Coding",
//         // minValue: 100
//     }


//     var model = {
//         valueOne: null,
//         valueTwo: null,
//         valueThree: null,
//         valueFour: null,
//         valueFive: null,
//         valueSix: null,
//         valueSeven: null
//     };
//     var label = map(tabledata, 'label')
//     var data1 = find(tabledata, function (o) { return o.label === "Musculoskeletal"; });
//     var data2 = find(tabledata, function (o) { return o.label === "GI"; });
//     var data3 = find(tabledata, function (o) { return o.label === "Cataract"; });
//     var data4 = find(tabledata, function (o) { return o.label === "Spinal Injections"; });
//     var data5 = find(tabledata, function (o) { return o.label === "Digestive"; });
//     var data6 = find(tabledata, function (o) { return o.label === "Eyes"; });
//     var result1 = pick(data1, keys(model));
//     var result2 = pick(data2, keys(model));
//     var result3 = pick(data3, keys(model));
//     var result4 = pick(data4, keys(model));
//     var result5 = pick(data5, keys(model));
//     var result6 = pick(data6, keys(model));
//     var Musculoskeletal = Object.values(result1)
//     var GI = Object.values(result2)
//     var Cataract = Object.values(result3)
//     var SpinalInjections = Object.values(result4)
//     var Digestive = Object.values(result5)
//     var Eyes = Object.values(result6)

//     const series = [
//         {
//             name: label[0],
//             data: Musculoskeletal,
//         },
//         {
//             name: label[1],
//             data: GI,
//         },
//         {
//             name: label[2],
//             data: Cataract,
//         },
//         {
//             name: label[3],
//             data: SpinalInjections,
//         },
//         {
//             name: label[4],
//             data: Digestive,
//         },
//         {
//             name: label[5],
//             data: Eyes,
//         },

//     ]

//     return (
//         <React.Fragment>
//             <Row>
//                 <Col>
//                     <Card >
//                         <CardHeader className="bg-transparent border-bottom text-uppercase h4">Specialty Based Volume -Modular Coding
//                         </CardHeader>
//                         <CardBody>
//                             <MultipleBarChart dataColors='["--bs-danger","--bs-primary", "--bs-success","--bs-info","--bs-secondary"]' tableColumns={tableColumns} dataseries={series} state={state} />
//                         </CardBody>
//                     </Card>
//                 </Col>
//                 {/* <Card >
//                     <CardHeader className="bg-transparent border-bottom text-uppercase h4">QUALITY AUDIT SUMMARY – Sep 2023
//                     </CardHeader>
//                     <CardBody>

//                     </CardBody>
//                 </Card> */}
//             </Row>
//         </React.Fragment>

//     )
// }

// export default SpecialityVolume


import React from "react"
import { Table, Card, CardBody, CardHeader, Col, Row } from "reactstrap"
import Pie from "../PieChart";
import ApexCorePie from "./Pie1Chart";


const SpecialityChart = () => {


    const tableData = [
        { name: "Musculoskeletal", value: 16372, valueTwo: 25.95 },
        { name: "GI", valueTwo: 16641, value: 26.37 },
        { name: "Cataract", value: 8128, valueTwo: 12.88 },
        { name: "Spinal Injections", value: 5691, valueTwo: 9.02 },
        { name: "Digestive", value: 2740, valueTwo: 4.34 },
        { name: "Eyes", value: 2536, valueTwo: 4.02 },
        { name: "Nervous System", value: 2260, valueTwo: 3.58 },
        { name: "Integumentary", value: 1785, valueTwo: 2.83 },
        { name: "Auditory System", value: 1349, valueTwo: 2.14 },
        { name: "Respiratory", value: 1531, valueTwo: 2.43 },
        { name: "Urology", value: 1373, valueTwo: 2.18 },
        { name: "Female System", value: 1120, valueTwo: 1.78 },
        { name: "Male System", value: 579, valueTwo: 0.92 },
        { name: "E/M", value: 519, valueTwo: 0.82 },
        { name: "Cardiovascular", value: 246, valueTwo: 0.39 },
        { name: "Dental", value: 147, valueTwo: 0.23 },
        { name: "Endocrine", value: 35, valueTwo: 0.06 },
        { name: "Hemic/Lymphatic", value: 43, valueTwo: 0.07 },
    ]
    const tableAvgData = [
        { name: "Musculoskeletal", value: 16268, valueTwo: 26.63 },
        { name: "GI", value: 14524, valueTwo: 23.55 },
        { name: "Cataract", value: 8108, valueTwo: 13.23 },
        { name: "Spinal Injections", value: 4796, valueTwo: 7.94 },
        { name: "Digestive", value: 3765, valueTwo: 6.17 },
        { name: "Eyes", value: 2798, valueTwo: 4.70 },
        { name: "Nervous System", value: 2338, valueTwo: 3.83 },
        { name: "Integumentary", value: 1982, valueTwo: 3.15 },
        { name: "Auditory System", value: 1544, valueTwo: 2.67 },
        { name: "Respiratory", value: 1330, valueTwo: 2.13 },
        { name: "Urology", value: 1269, valueTwo: 2.05 },
        { name: "Female System", value: 1098, valueTwo: 1.86 },
        { name: "Male System", value: 546, valueTwo: 0.87 },
        { name: "E/M", value: 567, valueTwo: 0.97 },
        { name: "Cardiovascular", value: 320, valueTwo: 0.52 },
        { name: "Dental", value: 101, valueTwo: 0.16 },
        { name: "Endocrine", value: 55, valueTwo: 0.09 },
        { name: "Hemic/Lymphatic", value: 50, valueTwo: 0.08 },
    ]


    return (
        <React.Fragment>
            <Row>
                <Card>
                    <CardHeader className="bg-transparent border-bottom text-uppercase h4 App ">Specialty Based Volume  – Modular Coding</CardHeader>
                </Card>
                <Col lg={6}>
                    <Card >
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6  ">Oct - 2023</CardHeader>
                        <CardBody>
                            {/* <ApexCorePie
                                data={tableData} /> */}
                            <Pie dataColors='["--bs-primary","--bs-warning", "--bs-danger","--bs-info", "--bs-success"]' option={tableData} />
                        </CardBody>
                    </Card>
                </Col>
                <Col lg={6}>
                    <Card >
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6  ">Apr-Sep - 2023 </CardHeader>
                        <CardBody>
                            {/* <ApexCorePie
                                data={tableAvgData} /> */}
                            <Pie dataColors='["blue","yellow", "red","skyBlue", "green","paleVioletRed","mediumPurple","orange"]' option={tableAvgData} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>

    )
}
export default SpecialityChart;