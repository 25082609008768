import React from 'react'
import { Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import HcPieChartCore from '../HcPieChartCore';
import HcTopClients from '../HcTopClients';


const DSpecialtyBasedVolume = () => {
    const tableColumns = [
        { label: "Specialties" }, { label: "No. of lines" },
        // {label:"%"}
    ]
    var total = 0;
    const tableData = [
        { name: "Ortho", valueOne: 1536370, value: 40.79 },
        { name: "Orthopedic Surgery", valueOne: 847537, value: 22.50 },
        { name: "Gastroenterology ", valueOne: 232761, value: 6.18 },
        { name: "Pain Management", valueOne: 223399, value: 5.93 },
        { name: "Ophthalmology", valueOne: 218737, value: 5.81 },
        { name: "ENT", valueOne: 129461, value: 3.44 },
        { name: "Podiatry", valueOne: 124128, value: 3.30 },
        { name: "Gynecology", valueOne: 114878, value: 3.05 },
        { name: "Anesthesiology ", valueOne: 102440, value: 2.72 },
        { name: "Urology", valueOne: 58620, value: 1.56 },
        { name: "Oncology", valueOne: 52308, value: 1.39 },
        { name: "Cardiology", valueOne: 43782, value: 1.16 },
        { name: "Ob-Gynecology ", valueOne: 25002, value: 0.66 },
        { name: "Plastic Surgery ", valueOne: 21559, value: 0.57 },
        { name: "Psychiatry", valueOne: 20095, value: 0.53 },
        { name: "Paediatrics", valueOne: 14275, value: 0.38 },
        { name: "Internal Medicine", valueOne: 1427, value: 0.04 }
    ]
    const tableData1 = [
        { name: "Ortho", valueOne: 1536370, value: 40.12 },
        { name: "Orthopedic Surgery", valueOne: 847537, value: 23.07 },
        { name: "Gastroenterology ", valueOne: 232761, value: 5.93 },
        { name: "Pain Management", valueOne: 223399, value: 5.83 },
        { name: "Ophthalmology", valueOne: 218737, value: 5.54 },
        { name: "ENT", valueOne: 129461, value: 3.43 },
        { name: "Podiatry", valueOne: 124128, value: 3.30 },
        { name: "Gynecology", valueOne: 114878, value: 2.96 },
        { name: "Anesthesiology ", valueOne: 102440, value: 2.57 },
        { name: "Urology", valueOne: 58620, value: 1.58 },
        { name: "Oncology", valueOne: 52308, value: 1.41 },
        { name: "Cardiology", valueOne: 43782, value: 1.11 },
        { name: "Ob-Gynecology ", valueOne: 25002, value: 0.66 },
        { name: "Plastic Surgery ", valueOne: 21559, value: 0.59 },
        { name: "Psychiatry", valueOne: 20095, value: 0.59 },
        { name: "Paediatrics", valueOne: 14275, value: 0.39 },
        { name: "Internal Medicine", valueOne: 1427, value: 0.03 }
    ]
    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h4 App" >SPECIALTY BASED VOLUME</CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row>
                 <Col lg={6}>
                    <HcTopClients />
                </Col>
                {/* <Col lg={6}>
                    <Card className='overflow'>
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Oct 2023</CardHeader>
                        <CardBody>
                            <HcPieChartCore data={tableData} />
                        </CardBody>
                    </Card>
                </Col> */}
                <Col lg={6}>
                    <Card >
                        <CardHeader className="bg-transparent border-bottom text-uppercase h6">Mar-Sep 2023</CardHeader>
                        <CardBody>
                            <HcPieChartCore data={tableData1} />
                        </CardBody>
                    </Card>
                </Col>
               
            </Row>
            <Row>
                {/* <Card className='overflow'>
                    <CardHeader className="bg-transparent border-bottom text-uppercase h6">Mar-Sep 2023</CardHeader>
                    <CardBody>
                        <HcPieChartCore data={tableData1} />
                    </CardBody>
                </Card> */}
            </Row>

        </React.Fragment>

    )
}

export default DSpecialtyBasedVolume
